<template>
  <div v-if="video.ext_id">
    <iframe
      class="video-viewer"
      :src="videoUrl"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <div class="time-frame">
      <div
        v-for="it in video.records"
        :key="it.uuid"
        v-bind:style="calStyle(it,video)"
        v-tooltip="`${it.start} ${it.end}: ${it.title}`"
        v-bind:class="{'bg-good':it.ext_id}"
        @dblclick="$emit('edit-record', it)"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    video: Object,
    toPlay: Array,
  },
  data() {
    return {};
  },
  methods: {
    calLeft(it, video) {
      let t1 = it.start;
      let t2 = video.duration;
      let v = (t1 / t2) * 100;
      return `${v}$`;
    },
    calWidth(it, video) {
      console.log(it);
      console.log(video);
    },
    calStyle(it, video) {
      let t = video.duration;
      let t1 = it.start;
      let t2 = it.end - t1;

      let v1 = (t1 / t) * 100;
      let v2 = (t2 / t) * 100;
      return {
        left: `${v1}%`,
        width: `${v2}%`,
      };
    }
  },
  computed: {
    videoUrl() {
      if (this.toPlay) {
        let s = this.toPlay[0];
        let e = this.toPlay[1];
        return `https://www.youtube.com/embed/${this.video.ext_id}?start=${s}&end=${e}&autoplay=1`;
      }
      return `https://www.youtube.com/embed/${this.video.ext_id}`;
    },
  },
};
</script>