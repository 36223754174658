<template>
  <div class="content">
    <div>
      <h3>{{ video.title }}</h3>
    </div>
    <div>
      <h6>{{ $t("Package") }}: {{ video.import_key }}</h6>
    </div>
    <div>
      <h6>{{ $t("Publish date") }}: {{ video.publish_date }}</h6>
    </div>
    <div>
      <h6>
        {{ $t("Duration") }}: {{ video.duration | formatTime }}
        <span class="btn-icon-host" v-on:click="editDuration()">
          <b-icon class="btn-icon" icon="pencil-square" variant="info"></b-icon>
        </span>
      </h6>
    </div>
    <div>
      <h6>{{ $t("Channel") }}: {{ video.channel }}</h6>
    </div>
    <div v-if="video.monitor_name || video.monitor_email">
      <h6>
        <b
          >{{ $t("Monitor") }}:
          {{ video.monitor_name ? video.monitor_name : video.monitor_email }}</b
        >
      </h6>
    </div>
    <div v-if="video.qc_name || video.qc_email">
      <h6>
        <b
          >{{ $t("QC") }}:
          {{ video.qc_name ? video.qc_name : video.qc_email }}</b
        >
      </h6>
    </div>
    <div v-html="video.description"></div>
  </div>
</template>
<script>
import time from "@/time.js";
import api from "@/api.js";

export default {
  props: {
    video: Object,
  },
  filters: {
    formatTime(value) {
      return time.toText(value);
    },
  },
  methods: {
    editDuration() {
      this.$dialog
        .prompt(
          {
            title: this.$t("Edit video duration"),
          },
          {
            promptHelp: "",
          }
        )
        .then((d) => {
          if (!time.validate(d.data)) {
            this.$dialog.alert(this.$t("wrong time format"));
            return;
          }
          var val = d.data;
          api.editVideoDuration(this.video.id, val).then(() => {
            this.video.duration = val;
          });
        });
    },
  },
};
</script>