<template>
  <div class="row">
    <table class="table col-md-4 offset-md-4 shadow-sm content">
      <thead>
        <tr>
          <th>Config</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="it in items" :key="it.name">
          <td>{{it.name}}</td>
          <td>{{it.value}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import api from "@/api.js";
export default {
  data() {
    return {
      items: [{}],
    };
  },
  methods: {
    load() {
      api.system().done((x) => {
        this.items = x;
      });
    },
  },
  mounted() {
    this.load();
  },
};
</script>