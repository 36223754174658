<template>
  <table class="table tableFixHead">
    <thead>
      <tr>
        <th scope="col" class="index-col">#</th>
        <th class="col-qc" scope="col">QC</th>
        <th scope="col" class="time-col">{{$t('Start')}}</th>
        <th scope="col" class="time-col">{{$t('Duration')}}</th>
        <th scope="col" class="time-col">{{$t('End')}}</th>
        <th scope="col">{{$t('Work')}}</th>
        <th scope="col">{{$t('Author 1')}}</th>
        <th scope="col">{{$t('Usage')}}</th>
        <th scope="col">{{$t('Kind')}}</th>
        <th scope="col">{{$t('Action')}}</th>
      </tr>
    </thead>
    <tbody class="padding-sm content">
      <tr
        v-for="(it,idx) in sortedRecords"
        :key="it.uuid"
        v-bind:id="it.uuid"
        v-bind:class="[it.uuid==cRecord.uuid?'active':'', `qc-status-${it.qc2_status}`]"
        @click="$emit('edit',it)"
      >
        <td class="index-col" v-bind:class="it.ext_id?'bg-good':'bg-normal'">
          <span class="lds-dual-ring" v-if="it.data_status==1"></span>
          <b-icon-exclamation-triangle-fill variant="warning" v-if="it.data_status==3"></b-icon-exclamation-triangle-fill>
          {{idx+1}} 
        </td>
        <td
          class="col-qc qc-status"
        >{{it.qc2_status|qc_status_text}}</td>
        <td class="time-col">{{it.start | timeDisplay}}</td>
        <td class="time-col">{{it | durationDisplay}}</td>
        <td class="time-col">{{it.end | timeDisplay}}</td>
        <td v-bind:title="it.title">{{it.title}}</td>
        <td v-bind:title="it.author1">{{it.author1}}</td>
        <td class="time-col">{{it.usage_kind|usageDisplay}}</td>
        <td class="time-col">{{kindDisplay(it.is_mashup)}}</td>
        <td class="record-fncs d-flex flex-row justify-content-center">
          <div :title="$t('Replay record')">
            <b-icon
              class="btn-icon"
              icon="caret-right-square-fill"
              variant="secondary"
              @click.stop="$emit('play',it)"
            ></b-icon>
          </div>
          <div :title="$t('Delete record')" v-if="canEdit()">
            <b-icon
              class="btn-icon"
              icon="trash-fill"
              variant="danger"
              title="Delete"
              @click.stop="$emit('delete', it)"
            ></b-icon>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import time from "@/time.js";
export default {
  props: {
    video: Object,
    cRecord: Object,
    mode: String,
  },
  computed: {
    sortedRecords() {
      if (this.video.records.length == 0) return [];
      let rs = [...this.video.records];
      rs.sort((a, b) => {
        return a.start - b.start;
      });
      return rs;
    },
  },
  methods: {
    kindDisplay(it) {
      if (it) return this.$t("Mashup");
      return "";
    },
    canEdit() {
      if (this.mode == "monitor") return this.video.status == -1;
      if (this.mode == "qc") return true;
    },
  },
  filters: {
    durationDisplay(it) {
      return time.toText(it.end - it.start);
    },
    timeDisplay(it) {
      return time.toText(it);
    },
    usageDisplay(it) {
      if (it == 0) return "Video";
      if (it == 1) return "Audio";
      if (it == 2) return "Karaoke";
      if (it == 3) return "Karaoke (MV)";
      if (it == 4) return "Karaoke (MIDI)";
      else return "Unknown";
    },
    qc_status_text(v) {
      if (v == 0) return "";
      if (v == 1) return "OK";
      if (v == 2) return "E";
      if (v == 3) return "D";
      if (v == 4) return "N";
      return `Unknown-${v}`;
    },
  },
};
</script>