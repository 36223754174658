import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import moment from 'moment'
import jQuery from "jquery";
import VuejsDialog from "vuejs-dialog";
import ReportError from "@/components/ReportError";

window.jQuery = window.$ = jQuery
Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  okText: "OK",
  cancelText: "Cancel",
  animation: "bounce",
});
Vue.dialog.registerComponent("ReportError", ReportError);


// import { BootstrapVue } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'


// Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.prototype.moment = moment


new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')
