<template>
  <div class="d-flex flex-column h-100">
    <div id="nav" class="p-2">
      <Nav></Nav>
    </div>
    <div id="main" class="mb-auto p-2 flex-fill">
      <router-view></router-view>
    </div>
    <div id="status" class="status-bar p-2">
      <StatusBar></StatusBar>
    </div>
  </div>
</template>
<script>
import Nav from '@/components/Nav'
import StatusBar from '@/components/StatusBar'
import api from '@/api'
export default {
  components: {
    Nav, StatusBar
  },
  mounted (){
    if(this.$router.currentRoute && this.$router.currentRoute.name=='mainWindow'){
      if(api.authenticated()){
        this.$router.replace('/monitor')
      }
      else{
        this.$router.replace('/login')
      }
    }
  }
}
</script>