import json from '@/suggestions.json'

let vsigns = [
    "aAeEoOuUiIdDyY",
    "áàạảãâấầậẩẫăắằặẳẵ",
    "ÁÀẠẢÃÂẤẦẬẨẪĂẮẰẶẲẴ",
    "éèẹẻẽêếềệểễ",
    "ÉÈẸẺẼÊẾỀỆỂỄ",
    "óòọỏõôốồộổỗơớờợởỡ",
    "ÓÒỌỎÕÔỐỒỘỔỖƠỚỜỢỞỠ",
    "úùụủũưứừựửữ",
    "ÚÙỤỦŨƯỨỪỰỬỮ",
    "íìịỉĩ",
    "ÍÌỊỈĨ",
    "đ",
    "Đ",
    "ýỳỵỷỹ",
    "ÝỲỴỶỸ"];
const suggester = {
    cText(text) {
        let t = vsigns[0]
        for (let i = 1; i < vsigns.length; i++) {
            const s = vsigns[i]
            const nc = t[i - 1]
            for (let j = 0; j < s.length; j++) {
                const c = s[j];
                text = text.replace(new RegExp(c, 'g'), nc)
            }
        }
        return text
    },
    find(text, type) {
        if (type == "author")
            return this.findAuthor(text);
        let idx = text.indexOf('/');
        if (idx > -1) {
            let title = text.substring(0, idx).trim().toLowerCase();
            let author = this.cText(text.substring(idx + 1).trim().toLowerCase());
            let songs = this.findSong(title, true);
            if (songs.length == 0)
                return songs;
            // filter author
            let out = [];
            for (let i = 0; i < songs.length; i++) {
                const it = songs[i];
                if (it.author_en.indexOf(author) >= 0)
                    out.push(it);
            }
            let max = 100;
            let o = out.slice(0, max);
            return o;
        }
        return this.findSong(text);
    },
    findSong(text, raw = false) {
        if (!text)
            return []

        let enText = this.cText(text.trim().toLowerCase())
        let d = json.songs;
        let depth = 10000;
        let r = []
        for (let i = 0; i < d.length; i++) {
            const it = d[i];
            const txt = json.texts[it[0]];
            let idx = txt[1].indexOf(enText)
            if (idx < 0)
                continue
            let f = {}
            let rText = txt[0].substring(idx, idx + text.length)
            let fText = txt[0].replace(rText, `<strong>${rText}</strong>`)
            f.text = txt[0];
            f.text_format = fText
            f.weight = idx;
            f.author = json.texts[it[1]][0];
            f.author_en = json.texts[it[1]][1];
            f.ext_id = json.ext_ids[it[2]];
            r.push(f)
            if (r.length > depth)
                break
        }
        r.sort((a, b) => {
            return a.weight - b.weight;
        })
        if (raw)
            return r;
        let max = 100;
        let o = r.slice(0, max);
        o.sort((a, b) => {
            if (a.text != b.text)
                return a.weight - b.weight;
            if (a.author == b.author)
                return 0;
            return a.author > b.author ? 1 : -1;
        })

        return o;
    },
    findAuthor(text) {
        if (!text)
            return []

        let enText = this.cText(text.trim().toLowerCase())
        let d = json.authors;
        let depth = 200;
        let r = []
        for (let i = 0; i < d.length; i++) {
            const it = d[i];
            const txt = json.texts[it];
            let idx = txt[1].indexOf(enText)
            if (idx < 0)
                continue
            let f = {}
            let rText = txt[0].substring(idx, idx + text.length)
            let fText = txt[0].replace(rText, `<strong>${rText}</strong>`)
            f.text = txt[0];
            f.text_format = fText
            f.weight = idx;
            r.push(f)
            if (r.length > depth)
                break
        }
        r.sort((a, b) => {
            return a.weight - b.weight;
        })
        let max = 100;
        let o = r.slice(0, max);
        o.sort((a, b) => {
            if (a.text != b.text)
                return a.weight - b.weight;
            if (a.author == b.author)
                return 0;
            return a.author > b.author ? 1 : -1;
        })

        return o;
    },
    match(title, author) {
        if (!title || !author)
            return null;
        title = title.normalize().toUpperCase();
        author = author.normalize().toUpperCase();
        let d = json.songs
        for (let i = 0; i < d.length; i++) {
            const it = d[i];
            if (json.texts[it[0]][0] == title
                && json.texts[it[1]][0] == author)
                return json.ext_ids[it[2]];
        }
        return null;
    }
}

export default suggester;