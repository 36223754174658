<template>
  <div>
    <div>Server: {{ serverStatus }}</div>
    <table class="table">
      <thead>
        <tr>
          <th v-for="(it, idx) in object" v-bind:key="idx">
            {{ it.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(it, idx) in data" :key="idx">
          <td v-for="(ij, j) in object" :key="j">
            <span v-if="ij.name=='download'"><a v-bind:href="it[ij.name]" target="_blank">Download</a></span>
            <div v-else-if="ij.name=='status'">
              <div v-if="it[ij.name]=='Running'" class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              {{it[ij.name]}}
            </div>
            <span v-else>{{it[ij.name]}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { bus } from "@/EventBus";

let reportBackend = process.env.VUE_APP_REPORT_API;

export default {
  data() {
    return {
      data: [],
      serverStatus: "OFF",
      debug: "debug",
      object: [
        {
          name: "id",
          text: "Id",
        },
        {
          name: "name",
          text: "Name",
        },
        {
          name: "created_by",
          text: "Created by",
        },
        {
          name: "created_date",
          text: "Created date",
        },
        {
          name: "type",
          text: "Type",
        },
        {
          name: "arguments",
          text: "Arguments",
        },
        {
          name: "status",
          text: "Status",
        },
        {
          name: "download",
          text: "Download",
        },
      ],
    };
  },
  methods: {
    generate(args) {
      if (!args.name || args.name.trim() == "") {
        this.$dialog.alert(this.$t("Name is required"));
        return;
      } else {
        this.conn.invoke("generate", args).catch(function (err) {
          bus.$emit("error", { msg: err });
          return console.error(err.toString());
        });
        return;
      }
    },
    load() {
      console.log("Excel report loaded");
      var connection = new HubConnectionBuilder()
        .withUrl(`${reportBackend}/report`, {
          accessTokenFactory: () => localStorage.Authorization,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();
      connection.on("OnData", (d) => {
        this.data = d;
      });
      connection.onclose(() => {
        this.serverStatus = "OFF";
      });
      connection.onreconnected(() => {
        this.serverStatus = "ON";
      });
      connection.onreconnecting(() => {
        this.serverStatus = "CONNECTING";
      });
      connection.on("NewItem", (d) => {
        this.data.splice(0, 0, d);
      });

      connection.on("ItemChanged", (d) => {
        const idx = this.data.findIndex((x) => x.id == d.id);
        this.$set(this.data, idx, d);
      });
      this.serverStatus = "CONNECTING";
      connection.start().then(() => {
        this.serverStatus = "ON";
        this.conn.invoke("register").catch(function (err) {
          bus.$emit("error", { msg: err });
          return console.error(err.toString());
        });
      });
      this.conn = connection;
    },
    test() {},
    rText(it, ij) {
      const d = it[ij.name];
      if (ij.name == "download" && d)
        return `<a href="${d}" target="_blank">Download</a>`;
      return d;
    },
  },
  mounted() {
    this.load();
  },
  destroyed() {
    if (this.conn) this.conn.stop();
  },
  filters: {},
};
</script>