<template>
  <div class="tools">
    <div>
      <h5>QC Link</h5>
      <div>
        <input v-model="youtube_id" placeholder="Youtube ID" />
      </div>
      <div>
        <input v-model="aibiz_code" placeholder="Aibiz Code" />
      </div>
      <div>
        <input v-model="time" placeholder="Time" />
      </div>
    </div>
    <button @click="generate()">Generate</button>
    <div>
      <a target="_blank" :href="link">{{ link }}</a>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
export default {
  data() {
    return {
      youtube_id: null,
      aibiz_code: null,
      time: null,
      link: null,
    };
  },
  methods: {
    generate() {
      this.link = null;
      api.qcLink(this).done((x) => {
        this.link = x;
      });
    },
  },
};
</script>