import jQuery from "jquery";
import {bus} from "@/EventBus";

const deferred = {
  done (data){
    var deferred = new jQuery.Deferred();
    setTimeout(function(){
      deferred.resolve(data);
    },1);
    return deferred.promise();
  },
  error (data){
    var deferred = new jQuery.Deferred();
    setTimeout(function(){
      deferred.reject(data);
    },1);
    deferred.fail((x)=>{
      bus.$emit('error', x)
    })
    return deferred.promise();
  }
}

export default deferred;