<template>
  <div>TEST</div>
</template>
<script>
export default {
  mounted() {
    console.log(localStorage.getItem("v-001"));
    localStorage.setItem(
      "v-0-001",
      JSON.stringify({
        id: 1,
        records: [{ uuid: "0" }, { uuid: "1" }],
      })
    );
  },
};
</script>