import Vue from 'vue'
import Router from 'vue-router'
import Videos from '@/components/Videos'
import Monitor from '@/components/Monitor'
import Manage from '@/components/Manage'
import Profile from '@/components/Profile'
import MainWindow from '@/components/MainWindow'
import Login from '@/components/Login'
import Test from '@/components/Test'
import Doc from '@/components/Document'
import System from '@/components/System'
import Report from '@/components/Report'
import Stats from '@/components/Stats'
import Tools from '@/components/Tools'
import Channels from '@/components/Channels'
import ImportData from '@/components/ImportData'
import VideoManager from '@/components/VideoManager'

Vue.use(Router);
const rout = new Router({
  // mode: 'history',
  routes: [
    {
      'name': 'mainWindow', path: '/', component: MainWindow
      , children: [
        {
          name: 'monitor', path: '/monitor', component: Videos, props: { mode: 'monitor' }, children:
            [{ name: 'mode_vid_monitor', path: ':id', component: Monitor, props: { mode: 'monitor' } }]
        },
        {
          name: 'qc', path: '/qc', component: Videos, props: { mode: 'qc' }, children:
            [{ name: 'mode_vid_qc', path: ':id', component: Monitor, props: { mode: 'qc' } }]
        },
        { path: '/my-stats', component: Stats },
        { path: '/manage', component: Manage },
        { path: '/system', component: System },
        { path: '/report', component: Report },
        { path: '/profile', component: Profile },
        { path: '/tools', component: Tools },
        { path: '/channels', component: Channels },
        { path: '/import-data', component: ImportData },
        { path: '/videos', component: VideoManager },
        { path: '/docs/:id', component: Doc, props: true }
      ]
    },
    { path: '/login', component: Login },
    { path: '/test', component: Test }
  ]
});
export default rout;