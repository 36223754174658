<template>
  <div class="d-flex flex-column h-100 video">
    <div class="busy" v-if="busy">
      <span class="spinner-border" role="status" aria-hidden="true"></span>
    </div>
    <VideoPlayer
      :video="video"
      :toPlay="toPlay"
      @edit-record="editRecord"
    ></VideoPlayer>
    <div class="p-2 d-flex flex-fill flex-row video-monitor">
      <div class="d-flex flex-column leftAside info">
        <ul class="nav nav-tabs">
          <li class="nav-item" v-on:click="infoView = 0">
            <a class="nav-link" v-bind:class="{ active: infoView == 0 }">{{
              $t("Video Information")
            }}</a>
          </li>
          <li class="nav-item" v-on:click="infoView = 1">
            <a class="nav-link" v-bind:class="{ active: infoView == 1 }">{{
              $t("Similar title")
            }}</a>
          </li>
        </ul>
        <div class="scroll-area flex-fill">
          <VideoInfo v-if="infoView == 0" :video="video"></VideoInfo>
          <ul v-if="infoView == 1" class="list-group">
            <li
              class="list-group-item"
              v-for="(i, idx) in matches"
              :key="idx"
              v-html="`${i.text_format} | ${i.author} ${i.ext_id}`"
            ></li>
          </ul>
        </div>
      </div>
      <div class="p-2 d-flex flex-fill flex-row d-form">
        <div class="w-50 form d-flex flex-column">
          <div class="d-flex toolbar">
            <button
              type="button"
              class="btn btn-primary"
              @click="edit"
              v-if="!isDirectQc && !editting"
            >
              <b-icon
                class="btn-icon text-light"
                icon="pencil-square"
                variant="info"
              ></b-icon>
              {{ startText }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="newRecord"
              v-if="canCreateRecord"
            >
              <b-icon
                class="btn-icon text-light"
                icon="plus-circle-fill"
                variant="info"
              ></b-icon>
              {{ $t("New record") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="requestEditRecord"
              v-if="!canEditRecord && editting"
            >
              <b-icon
                class="btn-icon text-light"
                icon="pencil-square"
                variant="info"
              ></b-icon>
              {{ $t("Edit record") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="trySaveRecord"
              v-if="canEditRecord"
            >
              <b-icon
                class="btn-icon text-light"
                icon="file-earmark-check-fill"
                variant="info"
              ></b-icon>
              {{ $t("Save record") }}
            </button>
            <span class="separator" v-if="editting"></span>
            <button
              type="button"
              v-if="canSkipVideo"
              class="btn btn-danger"
              @click="reportError"
            >
              <b-icon
                class="btn-icon text-light"
                icon="exclamation-circle-fill"
                variant="info"
              ></b-icon>
              {{ $t("Skip") }}
            </button>
            <button
              type="button"
              v-if="canCompleteVideo"
              class="btn btn-success"
              @click="complete"
            >
              <b-icon
                class="btn-icon text-light"
                icon="check-circle-fill"
                variant="info"
              ></b-icon>
              {{ $t("Complete") }}
            </button>
            <button type="button" class="btn" v-if="monitorLink">
              <b-icon
                class="btn-icon text-light"
                icon="folder-symlink-fill"
                variant="info"
              ></b-icon>
              <a :href="monitorLink">{{ $t("Goto monitor") }}</a>
            </button>
            <div class="flex-fill d-flex flex-row-reverse" v-if="isQ">
              <toggle-button
                @change="m1View = !m1View"
                :value="m1View"
                :labels="{ checked: 'QC', unchecked: 'M1' }"
                color="rgb(0, 163, 136)"
              ></toggle-button>
            </div>
          </div>
          <div class="flex-fill scroll-area">
            <RecordEdit
              v-if="!m1View"
              ref="redit"
              :video="video"
              :editting="canEditRecord"
              :cRecord="cRecord"
              :mode="mode"
              @next="newRecord()"
              @on-title-selected="infoView = 1"
            ></RecordEdit>
            <RecordEdit
              v-if="m1View"
              ref="redit"
              :video="video"
              :editting="false"
              :cRecord="m1RecordData"
              :mode="'none'"
            ></RecordEdit>
          </div>
        </div>
        <div class="d-flex flex-column records w-60">
          <div class="mb-auto p-2 flex-fill scroll-area">
            <RecordsTable
              :video="video"
              :cRecord="cRecord"
              :mode="mode"
              @edit="editRecord"
              @delete="deleteRecord"
              @play="playRecord"
            ></RecordsTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import api from "@/api.js";
import VideoPlayer from "@/components/VideoPlayer";
import VideoInfo from "@/components/VideoInfo";
import RecordEdit from "@/components/RecordEdit";
import RecordsTable from "@/components/RecordsTable";
import time from "@/time.js";
import Toasted from "vue-toasted";
import VueTheMask from "vue-the-mask";
import _ from "@/deferred";
import VTooltip from "v-tooltip";
import suggester from "@/suggester.js";
import ToggleButton from "vue-js-toggle-button";
import $ from "jquery";
Vue.use(ToggleButton);

Vue.use(VTooltip);
Vue.use(VueTheMask);
Vue.use(Toasted);

Vue.use(BootstrapVue);

export default {
  components: {
    VideoPlayer,
    VideoInfo,
    RecordEdit,
    RecordsTable,
  },
  name: "Video",
  props: {
    mode: String,
  },
  data() {
    return {
      dFields: [
        "usage_kind",
        "start",
        "end",
        "is_mashup",
        "note_code",
        "note_other",
        "title",
        "author1",
        "author2",
        "artist",
        "ext_id",
      ],
      direct: false,
      infoView: 0,
      m1View: false,
      busy: false,
      toPlay: null,
      video: {
        id: null,
        title: null,
        description: null,
        publish_date: null,
        records: [],
      },
      cRecord: { errors: {} },
    };
  },
  methods: {
    emptyRecord() {
      let start = null;
      if (this.video) {
        if (!this.video.records || this.video.records.length == 0) start = 0;
        for (let i = 0; i < this.video.records.length; i++) {
          const it = this.video.records[i];
          let v = it.end;
          if (v > start) start = v;
        }
      }
      return this.$refs.redit.emptyRecord(start);
    },
    mergeFields(source, target) {
      for (let j = 0; j < this.dFields.length; j++) {
        const f = this.dFields[j];
        target[f] = source[f];
      }
    },
    prepareQcVideo(x) {
      let records = [];
      for (let i = 0; i < x.m1.length; i++) {
        const it = { ...x.m1[i] };
        if (it.qc2_status == 2) {
          // merge
          let r = { ...it };
          let rq = x.qc2.filter((x) => {
            return x.record_id == it.id;
          })[0];
          this.mergeFields(rq, r);
          r.qc_note = rq.qc_note;
          r.qc_note_description = rq.qc_note_description;
          records.push(r);
        } else {
          it.qc_note = 0;
          records.push(it);
        }
      }
      for (let i = 0; i < x.qc2.length; i++) {
        const it = { ...x.qc2[i] };
        if (it.record_id) continue;
        it.qc2_status = 4;
        records.push(it);
      }
      x.records = records;
    },
    load() {
      if (this.isM)
        return api.getVideo(this.video.id).then((x) => {
          if (x.records) {
            x.records.forEach((r) => {
              r.data_status = 0;
              r.errors = this.$refs.redit.emptyErrors();
            });
          }
          this.video = x;
        });
      else if (this.isQ) {
        return api.getQcVideo(this.video.id).then((x) => {
          this.prepareQcVideo(x);
          if (x.records) {
            x.records.forEach((r) => {
              r.data_status = 0;
              r.errors = this.$refs.redit.emptyErrors();
            });
          }
          this.video = x;
        });
      }
    },
    populateRecordId(video, x) {
      for (let i = 0; i < video.records.length; i++) {
        const it = video.records[i];
        for (let j = 0; j < x.length; j++) {
          const ij = x[j];
          if (ij.uuid == it.uuid) it.id = ij.id;
        }
      }
    },
    complete() {
      if (this.cRecord.title && this.canEditRecord) {
        this.busy = true;
        this.saveRecord()
          .then(() => this.validateVideo())
          .then(() => api.complete(this.video, this.mode))
          .then(() => {
            if (this.isM) this.cRecord = this.emptyRecord();
          })
          .always(() => {
            this.busy = false;
          });
      } else {
        this.busy = true;
        this.validateVideo()
          .then(() => api.complete(this.video, this.mode))
          .then(() => {
            if (this.isM) this.cRecord = this.emptyRecord();
          })
          .always(() => {
            this.busy = false;
          });
      }
    },
    clear() {
      this.video.records = [];
    },
    validateVideo() {
      if (!this.video.records || this.video.records.length==0) 
        return _.error("Video doesn't have records. Please use skip function");
      if (this.isQ) {
        for (let i = 0; i < this.video.records.length; i++) {
          const r = this.video.records[i];
          if (r.qc2_status < 1)
            return _.error(this.$t("Please QC all records"));
        }
      }
      return _.done();
    },
    trySaveRecord() {
      this.$refs.redit.beforeSave().then(() => {
        this.saveRecord();
      });
    },
    newRecord(fromEmpty) {
      if (fromEmpty == true) {
        this.cRecord = this.emptyRecord();
        this.infoView = 0;
        return;
      }
      if (this.canEditRecord) {
        this.$refs.redit.beforeSave().then(() => {
          this.saveRecord();
          this.cRecord = this.emptyRecord();
          this.infoView = 0;
        });
      } else {
        this.saveRecord();
        this.cRecord = this.emptyRecord();
        this.infoView = 0;
      }
    },
    deleteRecord(record) {
      record.video_id = this.video.id;
      this.$dialog
        .confirm(this.$t("Are you sure you want to delete the record?"))
        .then(() => {
          if (this.isM) {
            api.deleteRecord(record, this.mode).done(() => {
              this.replaceRecord(record.uuid);
              if (this.cRecord.uuid == record.uuid)
                this.cRecord = this.emptyRecord();
            });
          } else if (this.isQ) {
            api.qcSaveRecord({ id: record.id, direction: 3 }).done((x) => {
              if (x == 5) {
                // remove
                this.replaceRecord(record.uuid);
              } else {
                record.qc2_status = x;
              }
              this.$forceUpdate();
            });
          }
        });
    },
    replaceRecord(uuid, newData = null) {
      let r = this.video.records.filter((x) => x.uuid == uuid);
      //removal
      if (r.length != 0)
        this.video.records.splice(this.video.records.indexOf(r[0]), 1);
      // edit record
      if (newData) this.video.records.push(newData);
    },
    saveRecord() {
      if (this.isM) {
        let r = this.$refs.redit.save();
        this.replaceRecord(this.cRecord.uuid, this.cRecord);
        return r;
      } else if (this.isQ) {
        this.$refs.redit.validateRecord();
        let r = this.cRecord;
        let ex = this.video.m1.filter((x) => {
          return x.uuid == r.uuid;
        });
        if (ex.length > 0) ex = ex[0];
        else ex = null;
        let change = false;
        if (ex) {
          for (let i = 0; i < this.dFields.length; i++) {
            const it = this.dFields[i];
            let va = ex[it];
            let vb = r[it];
            if (typeof va === "string") {
              va = va.toLowerCase();
            }
            if (typeof vb === "string") {
              vb = vb.toLowerCase();
            }
            if (va != vb) {
              change = true;
              break;
            }
          }
        }
        let direction = 1;
        if (!ex) direction = 4;
        else if (change) direction = 2;
        r.data_status = 1;

        let dr = { ...r };
        dr.direction = direction;
        dr.start = r.start;
        dr.end = r.end;
        dr.is_mashup = dr.is_mashup == true ? 1 : 0;
        dr.direct_qc = this.direct;
        let res = api
          .qcSaveRecord(dr)
          .done((x) => {
            r.qc2_status = x;
            r.data_status = 2;
          })
          .fail(() => {
            r.data_status = 3;
          });
        if (direction == 4) {
          let ex = this.video.records.filter((x) => {
            return x.uuid == r.uuid;
          });
          if (ex.length == 0) this.video.records.push(r);
        }
        return res;
      }
    },
    edit() {
      if (this.isM)
        api.requestEdit(this.video).done(() => {
          this.video.status = -1;
          if (!this.hasRecord()) {
            this.newRecord(true);
            if (this.video.duration < 7 * 60) {
              this.cRecord.start = "00:01";
              this.cRecord.end = time.toText(this.video.duration - 1);
            }
          }
        });
      else
        api.requestQc(this.video).done(() => {
          this.video.qc2_status = -1;
        });
    },
    hasRecord() {
      return this.video.records && this.video.records.length != 0;
    },
    requestEditRecord() {
      if (this.isQ) this.cRecord.qc2_status = 0;
      this.$forceUpdate();
    },
    editRecord(r) {
      this.infoView = 1;
      this.cRecord = r;
      // if(this.isQ){
      //   this.$router.push({
      //     name: "mode_vid_qc",
      //     params: { id: this.video.id },
      //     query: { record: r.id },
      //   });
      // }
      // else{
      //   this.cRecord = r;
      // }
    },

    reportError() {
      this.$dialog.confirm("Report Error", {
        view: "ReportError",
        html: true,
        animation: "fade",
        backdropClose: true,
        video: this.video,
        mode: this.mode,
        p: this,
      });
    },

    playRecord(i) {
      this.toPlay = [i.start, i.end];
    },
    reload() {
      this.load().done(() => {
        if (this.$route.query.mode == "direct") this.direct = true;
        if (this.$route.query.record) {
          this.cRecord = this.video.records.filter(
            (x) => x.id == this.$route.query.record
          )[0];
          Vue.nextTick(() => {
            $(`#${this.cRecord.uuid}`)[0].scrollIntoView({
              block: "center",
            });
          });
        } else if (this.isQ && this.video.records)
          this.cRecord = this.video.records[0];
        else this.cRecord = this.emptyRecord();
      });
    },
  },
  mounted() {
    this.cRecord = this.emptyRecord();
    this.video.id = parseInt(this.$router.currentRoute.params.id);
    this.reload();
  },
  beforeRouteUpdate(to, from, next) {
    this.video.id = parseInt(to.params.id);
    this.reload();
    next();
  },
  computed: {
    isM() {
      return this.mode == "monitor";
    },
    isQ() {
      return this.mode == "qc";
    },
    isDirectQc() {
      return this.mode == "qc" && this.direct;
    },
    editting() {
      if (this.isM) return this.video.status == -1;
      return this.video.qc2_status == -1;
    },
    canCompleteVideo() {
      if (this.isM) return this.video.status == -1;
      return false;
    },
    canCreateRecord() {
      if (this.isM) return this.video.status == -1;
      return true;
    },
    canSkipVideo() {
      if (this.isM) return this.video.status == -1;
      return true;
    },
    canEditRecord() {
      if (this.isDirectQc) return true;
      if (!this.editting) return false;
      if (this.isM) return true;
      if (this.isQ) return this.cRecord.qc2_status == 0;
      return false;
    },
    matches() {
      if (!this.cRecord.title) return [];
      return suggester.find(this.cRecord.title, "title");
    },
    startText() {
      if (this.isM)
        return this.video.status == 0 ? this.$t("Monitor") : this.$t("Edit");

      return this.$t("Edit");
    },
    m1RecordData() {
      let d = this.video.m1.filter((x) => {
        return x.uuid == this.cRecord.uuid;
      });

      if (d.length > 0) {
        d = { ...d[0] };
        d.errors = {};
        return d;
      }
      return { errors: {} };
    },
    monitorLink() {
      if (!this.isQ) return null;
      if (!this.video) return null;
      if (!this.cRecord) return null;
      if (!this.cRecord.id) return null;
      return `#/monitor/${this.video.id}?record=${this.cRecord.id}`;
    },
  },
  filter: {},
  watch: {
    cRecord() {
      if (!this.cRecord) return;
      if (this.cRecord.start == null || this.cRecord.end == null) return;
      let s = this.cRecord.start;
      let e = this.cRecord.end;
      if (e - s > 15) this.toPlay = [s, e];
    },
  },
};
</script>
