<template>
  <div
    class="d-flex flex-row h-100 videos"
    :class="[selectedId > 0 ? 'item-selected' : '']"
  >
    <div class="p-2 flex-grow-2">
      <div class="d-flex flex-column h-100 border leftAside collapse">
        <div class="p-2">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                class="nav-link"
                href="#/monitor"
                v-bind:class="{ active: mode == 'monitor' }"
                >Monitor</a
              >
            </li>
            <!-- <li class="nav-item">
              <a
                class="nav-link"
                href="#/qc"
                v-bind:class="{ active: mode == 'qc' }"
                >QC</a
              >
            </li> -->
            <li v-if="showRequestVideos">
              <button class="btn btn-success" @click="requestVideos()">
                <b-icon
                  class="btn-icon text-light"
                  icon="arrow-repeat"
                  variant="info"
                ></b-icon>
                {{ $t("Request videos") }}
              </button>
            </li>
          </ul>
        </div>
        <div class="mb-auto p-2 flex-fill scroll-area content list">
          <div
            class="d-flex flex-row list-item selectable"
            v-for="it in items"
            :key="it.id"
            @click="navItem(it.id)"
            :title="it.title"
            :class="[it.id == selectedId ? 'selected' : '']"
          >
            <img :src="it.ext_id | thumbnailUrl" />
            <div class="title flex-fill">
              <h6>{{ it.title }}</h6>
            </div>
            <div class="v-status" :class="it | vstatus(mode)"></div>
          </div>
        </div>
        <div>
          <paginate
            v-model="paging.cPage"
            :page-count="paging.pageCount"
            :page-range="2"
            :prev-text="'«'"
            :next-text="'»'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-link-class="'page-link'"
            :next-link-class="'page-link'"
            :click-handler="onNavigate"
          ></paginate>
        </div>
      </div>
    </div>
    <div class="p-2 flex-grow-1 h-100">
      <router-view></router-view>
      <div
        class="d-flex flex-column h-100 justify-content-center align-items-center"
        v-if="!videoOpened"
      >
        <h5>{{ $t("Select a video to start") }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import api from "@/api.js";
import { bus } from "@/EventBus";
import Paginate from "vuejs-paginate";
import VTooltip from "v-tooltip";

Vue.use(VTooltip);

Vue.component("paginate", Paginate);
export default {
  name: "Home",
  props: {
    msg: String,
    mode: String,
  },
  data: function () {
    return {
      urole: 0,
      items: [],
      selectedId: -1,
      paging: {
        pageCount: 0,
      },
    };
  },
  methods: {
    navItem(id) {
      this.$router
        .push({ name: `mode_vid_${this.mode}`, params: { id: id } })
        .catch(() => {});
    },
    requestVideos() {
      api.requestVideos().done(() => {
        this.load(0);
      });
    },
    updateSelection() {
      let to = this.$router.currentRoute;
      if (to && to.name == `mode_vid_${this.mode}`) {
        this.selectedId = to.params.id;
      } else {
        this.selectedId = null;
      }
    },
    updateItemStatus(video) {
      let v = this.items.filter((x) => x.id == video.id);
      if (v) {
        v[0].status = video.status;
        v[0].qc2_status = video.qc2_status;
      }
    },
    onNavigate(pIndex) {
      this.load(pIndex);
    },
    load(pIndex) {
      if (!pIndex) {
        pIndex = 0;
        if (this.$route.query.page) pIndex = parseInt(this.$route.query.page);
      }
      this.paging.cPage = pIndex;
      api.getVideos(pIndex, this.mode).done((x) => {
        this.items = x.data;
        this.paging.pageCount = x.last_page;
      });
    },
  },
  computed: {
    videoOpened() {
      return this.selectedId;
    },
    recordsModeUrl() {
      let urole = localStorage.Role;
      if (urole == 0) return "#/qc";
      return "#/qc";
    },
    showRequestVideos() {
      let complete = true;
      for (let i = 0; i < this.items.length; i++) {
        const it = this.items[i];
        if (it.status < 1) {
          complete = false;
          break;
        }
      }
      return this.paging.cPage == 0 && complete;
    }
  },
  created() {
    this.load();
  },
  watch: {
    $route() {
      this.updateSelection();
    },
    mode() {
      this.load();
    },
  },
  filters: {
    thumbnailUrl(youtubeId) {
      return `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`;
    },
    vstatus(video, mode) {
      let status = mode == "monitor" ? video.status : video.qc2_status;
      if (status == -1) return "status-ongoing";
      if (status == 0) return "status-none";
      if (status == 1) return "status-complete";
      if (status > 1) return "status-error";
    },
  },
  mounted() {
    this.urole = localStorage.Role;
    this.updateSelection();
    bus.$on("video-update", (v) => {
      this.updateItemStatus(v);
    });
  },
};
</script>
