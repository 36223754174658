<template>
  <div class="content">
    <div class="row">
      <label class="flabel col-md-3">{{ $t("Start") }}</label>
      <div class="col-md-9 d-flex flex-row">
        <div>
          <TimeInput v-model="cRecord.start" :disabled="!editting"></TimeInput>
          <small class="validation-message">{{ cRecord.errors.start }}</small>
        </div>
        <label class="flabel">{{ $t("End") }}</label>
        <div>
          <TimeInput v-model="cRecord.end" :disabled="!editting"></TimeInput>
          <small class="validation-message">{{ cRecord.errors.end }}</small>
        </div>

        <!-- <div class="form-check inlineCheckbox">
          <label class="flabel" for="chkIsMashUp">{{ $t("Mashup") }}</label>
          <input
            type="checkbox"
            v-model="cRecord.is_mashup"
            id="chkIsMashUp"
            class="form-check-input"
            tabindex="-1"
            :disabled="!editting"
          />
        </div> -->
      </div>
    </div>
    <div class="row">
      <label class="flabel col-md-3">{{ $t("Work") }}</label>
      <div class="col-md-9">
        <SuggestionInput
          :placeholder="$t('Input the work')"
          :value="cRecord.title"
          :disabled="!editting"
          type="title"
          @onTextChanged="
            (i) => {
              cRecord.title = i;
              cRecord.ext_id = null;
              cRecord.author1 = null;
            }
          "
          @onItemSelected="
            (i) => {
              cRecord.author1 = i.author;
              cRecord.ext_id = i.ext_id;
              $emit('on-title-selected');
            }
          "
          v-bind:title="cRecord.title"
        ></SuggestionInput>
        <small class="validation-message">{{ cRecord.errors.title }}</small>
      </div>
    </div>
    <div class="row">
      <label class="flabel col-md-3">{{ $t("Author 1") }}</label>
      <div class="col-md-9 d-flex flex-row">
        <SuggestionInput
          :placeholder="`${$t('Input the author 1')}`"
          :value="cRecord.author1"
          :disabled="!editting"
          type="author"
          class="flex-fill"
          v-bind:title="cRecord.author1"
          @onTextChanged="
            (i) => {
              (cRecord.author1 = i), (cRecord.ext_id = null);
            }
          "
          @onItemSelected="
            (i) => {
              cRecord.author1 = i.text;
              cRecord.ext_id = matchExtId();
            }
          "
        ></SuggestionInput>
      </div>
    </div>
    <div class="row">
      <label class="flabel col-md-3">{{ $t("Reference ID") }}</label>
      <div class="col-md-9 d-flex flex-row">
        <input
          :placeholder="`(${$t('Auto')})`"
          class="form-control"
          v-bind:title="cRecord.ext_id"
          v-model="cRecord.ext_id"
          disabled
        />
      </div>
    </div>
    <div class="row">
      <label class="flabel col-md-3">{{ $t("Note") }}</label>
      <div class="col-md-9">
        <div>
          <select
            v-model="cRecord.note_code"
            class="form-control"
            :disabled="!editting"
            :class="`sel-option-${cRecord.note_code}`"
          >
            <option value="0">({{ $t("None") }})</option>
            <option value="1">{{ $t("Note 1") }}</option>
            <option value="2">{{ $t("Note 2") }}</option>
            <option value="3">{{ $t("Note 3") }}</option>
            <option value="99">{{ $t("Other note") }}</option>
          </select>
        </div>
        <div v-if="cRecord.note_code == 99">
          <textarea
            v-model="cRecord.note_other"
            class="form-control"
            :disabled="!editting"
            :placeholder="`(${$t('Input note')})`"
            :max="1000"
          />
          <small class="validation-message">{{
            cRecord.errors.note_other
          }}</small>
        </div>
      </div>
    </div>
    <div class="row">
      <label class="flabel col-md-3">{{ $t("Usage") }}</label>
      <div class="col-md-9 radio-group d-flex flex-row">
        <table>
          <tbody>
            <tr>
              <td>
                <input
                  type="radio"
                  :id="`radio-0-${cRecord.uuid}`"
                  value="0"
                  v-model="cRecord.usage_kind"
                  :disabled="!editting"
                  @keydown="tabCheck($event, cRecord)"
                />
              </td>
              <td>
                <label
                  class="form-check-label"
                  :for="`radio-0-${cRecord.uuid}`"
                  >{{ $t("Video") }}</label
                >
              </td>
              <td>
                <input
                  type="radio"
                  :id="`radio-1-${cRecord.uuid}`"
                  value="1"
                  v-model="cRecord.usage_kind"
                  :disabled="!editting"
                  @keydown="tabCheck($event, cRecord)"
                />
              </td>
              <td>
                <label
                  class="form-check-label"
                  :for="`radio-1-${cRecord.uuid}`"
                  >{{ $t("Audio") }}</label
                >
              </td>
              <td>
                <input
                  type="radio"
                  :id="`radio-2-${cRecord.uuid}`"
                  value="3"
                  v-model="cRecord.usage_kind"
                  :disabled="!editting"
                  @keydown="tabCheck($event, cRecord)"
                />
              </td>
              <td>
                <label
                  class="form-check-label"
                  :for="`radio-2-${cRecord.uuid}`"
                  >{{ $t("Karaoke (MV)") }}</label
                >
              </td>
              <td>
                <input
                  type="radio"
                  :id="`radio-2-${cRecord.uuid}`"
                  value="4"
                  v-model="cRecord.usage_kind"
                  :disabled="!editting"
                  @keydown="tabCheck($event, cRecord)"
                />
              </td>
              <td>
                <label
                  class="form-check-label"
                  :for="`radio-2-${cRecord.uuid}`"
                  >{{ $t("Karaoke (MIDI)") }}</label
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="offset-md-3 col-md-9">
        <small class="validation-message">{{
          cRecord.errors.usage_kind
        }}</small>
      </div>
    </div>
    <div class="qc-part" v-if="mode == 'qc'">
      <h6>QC</h6>
      <div class="row">
        <label class="flabel col-md-3">{{ $t("Note") }}</label>
        <div class="col-md-9">
          <div>
            <select
              v-model="cRecord.qc_note"
              class="form-control"
              :disabled="!editting"
              :class="`sel-option-${cRecord.qc_note}`"
            >
              <option value="0">({{ $t("None") }})</option>
              <option value="10">{{ $t("Wrong monitoring rule") }}</option>
              <option value="11">{{ $t("Difficult monitoring") }}</option>
            </select>
          </div>
          <!-- <div v-if="cRecord.qc_note">
            <textarea
              v-model="cRecord.qc_note_description"
              class="form-control"
              :disabled="!editting"
              :placeholder="`(${$t('Input note')})`"
              :max="1000"
            />
            <small class="validation-message">{{
              cRecord.errors.qc_note_description
            }}</small>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SuggestionInput from "@/components/SuggestionInput";
import TimeInput from "@/components/TimeInput";

import api from "@/api.js";
import $ from "jquery";
import Vue from "vue";
import UUID from "vue-uuid";
import suggester from "@/suggester.js";
import time from "@/time.js";

Vue.use(UUID);

export default {
  props: {
    cRecord: Object,
    editting: Boolean,
    video: Object,
    mode: String,
  },
  components: { SuggestionInput, TimeInput },
  methods: {
    isOverlap(it, ij) {
      if (ij.start >= it.end) return false;
      if (ij.end <= it.start) return false;
      return true;
    },
    checkTimeOverllap(theRecord) {
      if (!this.video.records) return true;
      let counter = 0;
      let records = [];
      for (let i = 0; i < this.video.records.length; i++) {
        const it = this.video.records[i];
        if (this.mode == "qc" && it.qc2_status == 3) continue;
        records.push({
          uuid: it.uuid,
          title: it.title,
          start: it.start,
          end: it.end,
          is_mashup: it.is_mashup,
        });
      }
      let ex = this.video.records.filter((x) => x.uuid == theRecord.uuid);
      if (ex.length == 0 && theRecord.qc2_status != 3)
        records.push({
          uuid: theRecord.uuid,
          title: theRecord.title,
          start: theRecord.start,
          end: theRecord.end,
          is_mashup: theRecord.is_mashup,
        });
      let checkMashUp = false;
      // group mashup
      let vids = [];
      for (let i = 0; i < records.length; i++) {
        const it = records[i];
        let start = it.start;
        let end = it.end;
        if (it.is_mashup && checkMashUp) {
          let found = false;
          for (let j = 0; j < vids.length; j++) {
            const jt = vids[j];
            if (!jt.is_mashup) continue;
            // outside
            if (jt.start > end || jt.end < start) continue;
            found = true;
            jt.start = start < jt.start ? start : jt.start;
            jt.end = end > jt.end ? end : jt.end;
            jt.title = `${jt.title} + ${it.title}`;
            break;
          }
          if (!found)
            vids.push({
              uuid: it.uuid,
              title: it.title,
              start: start,
              end: end,
              is_mashup: true,
            });
        } else {
          vids.push({
            uuid: it.uuid,
            title: it.title,
            start: start,
            end: end,
            is_mashup: false,
          });
        }
      }

      if (vids.length > 1) {
        for (let i = 0; i < vids.length - 1; i++) {
          const it = vids[i];
          for (let j = i + 1; j < vids.length; j++) {
            const ij = vids[j];
            if (this.isOverlap(it, ij)) {
              return (
                this.$t("Time overlap") +
                `: ${it.title}(${time.toDurationText(it.start, it.end)}) <> ${
                  ij.title
                }(${time.toDurationText(ij.start, ij.end)})`
              );
            }
            if (this.isOverlap(ij, it)) {
              return (
                this.$t("Time overlap") +
                `: ${it.title}(${time.toDurationText(it.start, it.end)}) <> ${
                  ij.title
                }(${time.toDurationText(ij.start, ij.end)})`
              );
            }
          }
        }
      }

      for (let i = 0; i < vids.length; i++) {
        const it = vids[i];
        counter += it.end - it.start;
      }
      if (counter > this.video.duration) {
        return "Time out of range";
      }
      return true;
    },
    isTextEmpty(x) {
      return !x || x.trim() == "";
    },
    validateRecord() {
      this.cRecord.errors = this.emptyErrors();
      let errors = this.cRecord.errors;
      errors.start = errors.end = errors.title = errors.author1 = errors.author2 = errors.artist = null;if (this.cRecord.start > this.video.duration)
        errors.start = this.$t("Time out of range");
      if (this.cRecord.end > this.video.duration)
        errors.end = this.$t("Time out of range");
      if (
        !errors.start &&
        !errors.end &&
        this.cRecord.start >= this.cRecord.end
      ) {
        errors.start = this.$t("Start >= end");
        errors.end = this.$t("Start >= end");
      }
      if (this.cRecord.usage_kind == -1)
        errors.usage_kind = this.$t("Usage kind is required");
      if (this.isTextEmpty(this.cRecord.title))
        errors.title = this.$t("Title is required");
      if (this.isTextEmpty(this.cRecord.author1))
        errors.author1 = this.$t("Author is required");
      if (this.cRecord.note_code != 99) this.cRecord.note_other = null;
      if (this.cRecord.note_code == 99 && !this.cRecord.note_other)
        errors.note_other = this.$t("Note is required");
      this.$forceUpdate();
      for (const k in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, k)) {
          let e = errors[k];
          if (e) {
            this.$toasted.show(this.$t("Validation failed"), {
              theme: "bubble",
              position: "bottom-right",
              duration: 3000,
            });
            throw "Validation failed";
          }
        }
      }
      let v1 = this.checkTimeOverllap(this.cRecord);
      if (v1 !== true) {
        this.$toasted.show(this.$t(v1), {
          theme: "bubble",
          position: "bottom-right",
          duration: 3000,
        });
        throw v1;
      }
      return true;
    },
    beforeSave() {
      this.validateRecord();
      let deferred = new $.Deferred();
      deferred.resolve(true);

      return deferred;
    },
    save() {
      this.validateRecord();
      let record = this.cRecord;
      record.data_status = 1;
      let s = { ...this.cRecord };
      s.video_id = this.video.id;
      s.title = s.title.toUpperCase();
      s.is_mashup = s.is_mashup ? 1 : 0;
      return api
        .saveRecord(s)
        .done((x) => {
          record.id = x;
          record.data_status = 2;
        })
        .fail(() => {
          record.data_status = 3;
        });
    },
    timeMaskUp(evt, field) {
      // let ch = evt.which ? evt.which : evt.keyCode;
      // let num = ch >= 48 && ch <= 57;
      // if (num) {
        let v = this.cRecord[field];
        if (!v) return;
        v = v.replace(/:/g, "");
        let len = v.length;
        if (len > 2) {
          v = [v.slice(0, 2), ":", v.slice(2)].join("");
          if (len > 4) v = [v.slice(0, 5), ":", v.slice(5)].join("");
          this.cRecord[field] = v;
        }
        if (v.length == 8 && !this.isSel()) this.focusNext();
      // }
    },

    isSel() {
      var text = $(":focus")[0];
      var t = text.value.substr(
        text.selectionStart,
        text.selectionEnd - text.selectionStart
      );
      return t;
    },

    focusNext() {
      let c = $(":focus");
      let inputs = c.closest(".content").find("input");
      for (let i = 0; i < inputs.length; i++) {
        const it = inputs[i];
        if (it == c[0] && i < inputs.length - 1) {
          inputs[i + 1].focus();
          inputs[i + 1].select();
          break;
        }
      }
    },
    tabCheck(e) {
      let TAB = 9;
      if (e.shiftKey) return;
      if (e.keyCode == TAB) {
        this.$emit("next");
        e.preventDefault();
      }
    },
    emptyRecord(start) {
      $("#inputStart").focus();
      $("#inputStart").select();
      return {
        uuid: this.$uuid.v4(),
        start: start,
        end: null,
        title: null,
        author1: null,
        author2: null,
        artist: null,
        usage_kind: -1,
        is_mashup: false,
        note_code: 0,
        note_other: null,
        errors: this.emptyErrors(),
        qc2_status: 0,
        data_status: 0, // 0: none, 1:saving, 2:saved, 3: error
        video_id: this.video.id,
        qc_note: 0,
      };
    },
    matchExtId() {
      let x = suggester.match(this.cRecord.title, this.cRecord.author1);
      if (x) return x;
      return null;
    },
    emptyErrors() {
      return {
        start: null,
        end: null,
        title: null,
        author1: null,
        author2: null,
        artist: null,
        note_other: null,
        qc_note_description: null,
      };
    },
  },
  data(){
    return {
      testTimeInput: 3600
    }
  },
  watch: {
    testTimeInput() {
      console.log(this.testTimeInput)
    },
  },
};
</script>