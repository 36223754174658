<template>
   <div class="d-flex flex-row status-bar justify-content-between">
      <div class="p-2 status-item">Copyright © AIBIZ 2020</div>
      <!-- <div class="lds-dual-ring"></div> -->
    </div>
</template>
<script>
export default {
  
}
</script>