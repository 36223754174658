import _ from '@/deferred'
// import instance from '@/api_mock'
import instance from '@/api_real'
import { bus } from "@/EventBus";
const api = {
  getVideos(pIndex, mode) {
    return instance.getVideos(pIndex, mode);
  },
  getRecords(pIndex, mode) {
    return instance.getRecords(pIndex, mode);
  },
  getRecord(id, mode) {
    return instance.getRecord(id, mode);
  },
  requestEdit(video) {
    return instance.requestEdit(video.id).then(() => {
      video.status = -1;
      bus.$emit("video-update", video);
      return video;
    })
  },
  requestQc(video) {
    return instance.requestQc(video.id).then(() => {
      video.qc2_status = -1;
      bus.$emit("video-update", video);
      return video;
    })
  },
  newRecord(video) {
    let start = null;
    if (!video.records || video.records.length == 0)
      start = 0
    for (let i = 0; i < video.records.length; i++) {
      const it = video.records[i];
      let v = it.end
      if (v > start)
        start = v
    }

    return _.done({
      id: null,
      title: '',
      author1: '',
      author2: '',
      artist: '',
      start: start,
      end: null,
      usage_kind: 0
    })
  },
  profile() {
    return instance.profile()
  },
  saveProfile($name, $cpassword, $password) {
    return instance.saveProfile($name, $cpassword, $password);
  },
  delUser(uid) {
    return instance.delUser(uid);
  },
  lockUser(uid) {
    return instance.lockUser(uid);
  },
  qcLink(data) {
    return instance.qcLink(data);
  },
  getVideo(id) {
    return instance.getVideo(id)
  },
  getQcVideo(id) {
    return instance.getQcVideo(id)
  },
  qcSaveRecord(record) {
    return instance.qcSaveRecord(record);
  },
  signin(email, password) {
    return instance.signin(email, password)
      .done((x) => {
        localStorage.Authorization = x[0]
        localStorage.Role = x[1]
        localStorage.User = x[2];
        bus.$emit('auth');
      })
  },
  signout() {
    return instance.signout()
      .done(() => {
        localStorage.removeItem('Authorization')
        localStorage.removeItem('Role')
        localStorage.removeItem('User')
        localStorage.removeItem('_store')
      });
  },
  authenticated() {
    return localStorage.Authorization;
  },
  complete(video, mode) {
    return instance.complete(video, mode).done(() => {
      if (mode == 'monitor')
        video.status = 1;
      else if (mode == 'qc')
        video.qc2_status = 1;
      bus.$emit("video-update", video);
    })
  },
  saveRecord(record) {
    return instance.saveRecord(record);
  },
  qc_done(rid, mode) {
    return instance.qc_done(rid, mode);
  },
  qc_change(record, mode) {
    return instance.qc_change(record, mode);
  },
  qc_request_edit(id, mode) {
    return instance.qc_request_edit(id, mode)
  },
  deleteRecord(record, mode) {
    return instance.deleteRecord(record, mode);
  },
  reportError(video, error, reason, mode) {
    return instance.reportError(video, error, reason, mode).done(() => {
      if (mode == 'monitor')
        video.status = error;
      else
        video.qc2_status = error;
      bus.$emit("video-update", video);
    })
  },
  getUsers() {
    return instance.getUsers();
  },
  newUser(email, password, role, name) {
    return instance.newUser(email, password, role, name)
  },
  autoAssign() {
    return instance.autoAssign()
  },
  sysStat() {
    return instance.sysStat()
  },
  userStat(uid) {
    return instance.userStat(uid)
  },
  system() {
    return instance.system();
  },
  report(reportType, params) {
    return instance.report(reportType, params);
  },
  videos(args) {
    return instance.videos(args);
  },
  getChannels() {
    return instance.getChannels();
  },
  getImportData() {
    return instance.getImportData();
  },
  requestVideos() {
    return instance.requestVideos();
  },
  editVideoDuration(vid, duration) {
    return instance.editVideoDuration(vid, duration);
  },
  dev() {
    return instance.dev();
  },
  lockAssignment(uid, value) {
    return instance.lockAssignment(uid, value);
  },
  _titleSuggestion: null,
  _manSuggestion: null,
  suggestionFor(type) {
    if (type == "title") {
      if (this._titleSuggestion)
        return _.done(this._titleSuggestion)
      return instance.suggestionFor(type).done((x) => {
        this._titleSuggestion = x
      });
    }
    if (type == "man") {
      if (this._manSuggestion)
        return _.done(this._manSuggestion)
      return instance.suggestionFor(type).done((x) => {
        this._manSuggestion = x
      });
    }
  }
}
export default api;