<template>
  <div class="new-user">
    <h5 class="card-title">New User</h5>
    <b-form-group label="Email" label-for="txtEmail">
      <b-form-input id="txtEmail" v-model="email" type="email" placeholder="Email"></b-form-input>
    </b-form-group>
    <b-form-group label="Password" label-for="txtPassword">
      <b-form-input id="txtPassword" v-model="password" placeholder="Password"></b-form-input>
    </b-form-group>
    <b-form-group label="Full Name" label-for="txtName">
      <b-form-input id="txtName" v-model="name" placeholder="(Optional)"></b-form-input>
    </b-form-group>
    <b-form-group label="Role" label-for="selRole">
      <b-form-select v-model="role" id="selRole" class="form-control">
        <b-form-select-option value="0">Monitor</b-form-select-option>
        <b-form-select-option value="2">QC</b-form-select-option>
        <b-form-select-option value="3">QC Lead</b-form-select-option>
        <b-form-select-option value="1">Admin</b-form-select-option>
      </b-form-select>
    </b-form-group>
    <div class="clearfix toolbar">
      <button class="btn btn-primary float-right" @click="handleOk">OK</button>
      <button class="btn btn-secondary float-right" @click="handleCancel">Cancel</button>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
import VuejsDialogMixin from "vuejs-dialog/dist/vuejs-dialog-mixin.min.js";
export default {
  mixins: [VuejsDialogMixin],
  data() {
    return {
      name: null,
      email: "",
      password: "12345678",
      role: 0,
    };
  },
  methods: {
    handleOk() {
      api.newUser(this.email, this.password, this.role, this.name).done(() => {
        this.proceed();
      });
    },
    handleCancel() {
      this.cancel();
    },
  },
};
</script>