<template>
  <div class="channels d-flex flex-column h-100">
    <div class="flex-fill scroll-area">
      <div class="container">
        <div class="row">
          <table class="table col-md-10 offset-md-1 shadow-sm content">
            <thead>
              <tr>
                <th>Id</th>
                <th>Youtube ID</th>
                <th>Title</th>
                <th>Import date</th>
                <th>Videos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in items" :key="i.id">
                <td>{{ i.id }}</td>
                <td>
                  <a :href="`https://www.youtube.com/channel/${i.ext_id}`">{{
                    i.ext_id
                  }}</a>
                </td>
                <td>{{ i.title }}</td>
                <td>{{ moment(i.created_date).format("YYYY-MM-DD") }}</td>
                <td>
                  <div>
                    <span class="sm-text black"
                      ><a
                        :href="`/#/videos?cn=${i.id}&opt=0`"
                        v-bind:title="$t('total videos')"
                      >
                        {{ i.video_count }}</a
                      ></span
                    >
                    <span class="sm-text good"
                      ><a
                        :href="`/#/videos?cn=${i.id}&opt=1`"
                        v-bind:title="$t('remaining videos')"
                        >{{ i.video_count - i.video_complete_count }}</a
                      ></span
                    >
                     <span class="sm-text blue"
                      ><a
                        :href="`/#/videos?cn=${i.id}&opt=1`"
                        v-bind:title="$t('completed videos')"
                        >{{i.video_complete_count }}</a
                      ></span
                    >
                    <span class="sm-text last bad"
                      ><a
                        :href="`/#/videos?cn=${i.id}&opt=2`"
                        v-bind:title="$t('skipped videos')"
                        >{{ i.video_skip }}</a
                      ></span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
export default {
  data() {
    return {
      items: [],
    };
  },
  methods: {
    load() {
      api.getChannels().done((x) => {
        this.items = x;
      });
    },
  },
  mounted() {
    this.load();
  },
};
</script>