<template>
  <div class="d-flex flex-row justify-content-center align-items-center profile">
    <b-form class="p-2 mb-auto shadow-sm">
       <b-form-group
        label="Email"
        label-for="email"
        >
          <b-form-input
            id="email"
            v-model="email"
            type="email"
            disabled
            placeholder="Enter email"
          ></b-form-input>
        </b-form-group>
       <b-form-group
        label="Name"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="name"
          placeholder="Enter your name"
        ></b-form-input>
      </b-form-group>
<!--      
      <b-form-group label="Password">
        <b-button class="btn-danger" @click="needChangePassword=!needChangePassword">Change password</b-button>
      </b-form-group>
      <b-form-group
        label="Current password"
        label-for="cPassword"
        v-if="needChangePassword"
      >
        <b-form-input
          id="cPassword"
          v-model="cPassword"
          type="password"
          placeholder="Current password"
        ></b-form-input>
        <small v-if="!cPassword" class="form-text text-muted validation-label">{{$t('Current password is required')}}</small>
      </b-form-group>
      <b-form-group
        label="New password"
        label-for="nPassword"
        v-if="needChangePassword"
      >
        <b-form-input
          id="nPassword"
          v-model="nPassword"
          type="password"
          placeholder="New password"
        ></b-form-input>
        <small v-if="!pMatched" class="form-text text-muted validation-label">{{$t('New password not match')}}</small>
      </b-form-group>
       <b-form-group
        label="Confirm new password"
        label-for="nPassword2"
        v-if="needChangePassword"
      >
        <b-form-input
          id="nPassword2"
          v-model="nPassword2"
          type="password"
          placeholder="Confirm new password"
        ></b-form-input>
        <small v-if="!pMatched" class="form-text text-muted validation-label">{{$t('New password not match')}}</small>
      </b-form-group> -->
      <div class="d-flex flex-row justify-content-center align-items-center profile">
        <b-button id="btnSave" variant="primary" @click="save">Save</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import api from "@/api.js";
export default {
  data (){
    return {
      email: '',
      name: '',
      needChangePassword: false,
      cPassword:'',
      nPassword:'',
      nPassword2:''
    }
  },
  methods:{
    save(){
      if(this.needChangePassword && (!this.pMatched || !this.cPassword))
        return
      api.saveProfile(this.name, this.needChangePassword? this.cPassword:null, this.needChangePassword? this.nPassword:null)
      .done(()=>{
        this.$dialog.alert(this.$t('Success'))
        localStorage.User = this.name
      })
    }
  },
  mounted (){
    api.profile().done((x)=>{
      this.email=x.email;
      this.name = x.name;
    })
  },
  computed:{
    pMatched(){
      return this.nPassword==this.nPassword2
    }
  }
}
</script>