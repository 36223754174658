<template>
  <div class="d-flex flex-row h-100">
    <div class="report-nav">
      <label for="report">{{ $t("Report type") }}</label>
      <b-form-select
        class="form-control"
        id="report"
        v-model="report"
        :options="reports"
      ></b-form-select>
      <div v-if="report" class="report-params">
        <div v-if="report.type == 'Excel'" class="excel-params">
          <label for="expfor">{{ $t("Export for") }}</label>
          <b-form-select
            class="form-control"
            id="expfor"
            v-model="excel.expfor"
            :options="expfors"
          ></b-form-select>
          <label for="expfor">{{ $t("Package") }}</label>
          <b-form-textarea
            class="form-control"
            v-model="excel.packages"
            v-bind:placeholder="$t('Input packages. Comma delimitor (,)')"
          ></b-form-textarea>
          <label for="expfor">{{ $t("Channel") }}</label>
          <b-form-textarea
            class="form-control"
            v-model="excel.channels"
            v-bind:placeholder="$t('Input channels. Comma delimitor (,)')"
          ></b-form-textarea>
          <label for="expfor">{{ $t("Name") }}</label>
          <b-form-textarea
            class="form-control"
            v-model="excel.name"
            v-bind:placeholder="$t('Report name (required)')"
          ></b-form-textarea>
          <label for="refcode">{{ $t("Ref Code") }}</label>
          <b-form-select
            class="form-control"
            id="refcode"
            v-model="excel.code"
            :options="codes"
          ></b-form-select>
          <label for="refTimeType">{{ $t("Time of") }}</label>
          <b-form-select
            class="form-control"
            id="refTimeType"
            v-model="excel.isQcDate"
            :options="timeTypes"
          ></b-form-select>
        </div>
        <label for="dstart">{{ $t("Start date") }}</label>
        <b-form-datepicker
          id="dstart"
          v-model="start"
          placeholder="Start date"
          :date-format-options="{
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }"
          right
        ></b-form-datepicker>
        <label for="dend">{{ $t("End date") }}</label>
        <b-form-datepicker
          id="dend"
          v-model="end"
          placeholder="End date"
          :date-format-options="{
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }"
          right
        ></b-form-datepicker>
        <div class="toolbar">
          <button
            class="btn btn-primary"
            v-if="report && report.type == 'dt'"
            @click="load"
          >
            {{ $t("Load") }}
          </button>
          <button
            class="btn btn-primary"
            v-if="report && report.type == 'Excel'"
            @click="generateReport"
          >
            {{ $t("Generate report") }}
          </button>
        </div>
      </div>
    </div>
    <div class="content flex-fill d-flex flex-column">
      <div class="flex-fill scroll-area">
        <table class="table" v-if="report && report.type == 'dt'">
          <thead>
            <tr>
              <th v-for="(it, idx) in object" v-bind:key="idx">
                {{ it.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(it, idx) in data" :key="idx">
              <td v-for="(ij, j) in object" :key="j">
                {{ it[ij.name] }}
              </td>
            </tr>
          </tbody>
        </table>
        <ExcelReport
          ref="excelReport"
          v-if="report && report.type == 'Excel'"
        ></ExcelReport>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
import ExcelReport from "@/components/ExcelReport";
import $ from "jquery";

export default {
  components: {
    ExcelReport,
  },
  data() {
    return {
      expfors: [
        {
          value: "QC",
          text: this.$t("For QC"),
        },
        {
          value: "Client",
          text: this.$t("For Client"),
        },
        {
          value: "Accountant",
          text: this.$t("For Accountant"),
        },
      ],
      reports: [
        {
          value: null,
          text: this.$t("Please select a report"),
          disabled: true,
        },
        {
          value: { name: "MonitorPerformance", type: "dt" },
          text: this.$t("Monitor performance"),
        },
        {
          value: { name: "QcPerformance", type: "dt" },
          text: this.$t("QC performance"),
        },
        {
          value: null,
          text: "---------------------------",
          disabled: true,
        },
        {
          value: { name: "Records", type: "Excel" },
          text: this.$t("Records report"),
        },
      ],
      codes: [
        {
          value: "Both",
          text: this.$t("RefBoth"),
        },
        {
          value: "Yes",
          text: this.$t("RefYes"),
        },
        {
          value: "No",
          text: this.$t("RefNo"),
        },
      ],
      timeTypes:[
        {
          value: false,
          text: this.$t("MonitorDate"),
        },
        {
          value: true,
          text: this.$t("QcDate"),
        },
      ],
      excel: {
        name: null,
        expfor: "QC",
        packages: null,
        channels: null,
        code: "Both",
        isQcDate: false
      },

      report: null,
      start: null,
      end: null,
      paging: {
        data: [],
        pageCount: 10,
        pageSize: 100,
        cPage: 0,
        nav(idx) {
          this.cPage = idx;
        },
        setData(x) {
          this.data = x;
          this.cItems = x.slice(0, this.pageSize);
          this.pageCount = x.length / this.pageSize;
        },
        cItems: [{}, {}],
      },
      data: null,
      object: null,
    };
  },
  methods: {
    generateReport() {
      const args = $.extend({}, this.excel);
      args.start = this.start;
      args.end = this.end;
      return this.$refs.excelReport.generate(args);
    },
    load() {
      let params = {
        start: this.start,
        end: this.end,
      };
      api.report(this.report.name, params).done((x) => {
        if (this.report.name == "MonitorPerformance") {
          this.object = [
            {
              name: "id",
              text: "Id",
            },
            {
              name: "name",
              text: "Monitor",
            },
            {
              name: "email",
              text: "Email",
            },
            {
              name: "recordCount",
              text: this.$t("record count"),
            },
            {
              name: "recordCountGood",
              text: this.$t("Good records"),
            },
            {
              name: "videoCount",
              text: this.$t("video count"),
            },
            {
              name: "videoCountSkip",
              text: this.$t("Skip videos"),
            },
          ];
        } else if (this.report.name == "QcPerformance") {
          this.object = [
            {
              name: "id",
              text: "Id",
            },
            {
              name: "name",
              text: "QC",
            },
            {
              name: "email",
              text: "Email",
            },
            {
              name: "recordCount",
              text: this.$t("record count"),
            },
          ];
        }
        this.data = x;
      });
    },
  },
  computed: {
    _expfor() {
      return this.excel.expfor;
    },
  },
  watch: {
    _expfor() {
      if (this.excel.expfor == "QC") this.excel.code = "Both";
      else if (this.excel.expfor == "Client") this.excel.code = "Yes";
      else if (this.excel.expfor == "Accountant") this.excel.code = "Both";
    },
  },
};
</script>