<template>
  <vue-suggestion
    :items="items"
    inputClasses="form-control"
    :placeholder="placeholder"
    :setLabel="setLabel"
    :itemTemplate="itemTemplate"
    :disabled="disabled"
    @changed="onChanged"
    @selected="onSelected"
    @key-down="onNav(true)"
    @key-up="onNav(false)"
    v-model="item"
    ref="ctl"
  ></vue-suggestion>
</template>
<script>
import Vue from "vue";
import VueSuggestion from "vue-suggestion";
import itemTemplate from "@/components/ItemTemplate.vue";
import suggester from "@/suggester.js";
import $ from "jquery";
$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(".vs__list").scrollTop();
  var viewportBottom = viewportTop + $(".vs__list").height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};
Vue.use(VueSuggestion);
export default {
  name: "SuggestionInput",
  props: {
    type: { type: String },
    value: { type: String },
    field: { type: String },
    secondField: { type: String },
    placeholder: { type: String },
    disabled: {},
  },
  data() {
    return {
      itemTemplate: itemTemplate,
      items: [],
      item: { text: this.value },
      withinSelected: false,
    };
  },
  methods: {
    setLabel(v) {
      return v.text;
    },
    onSelected(x) {
      this.item.text = x.text;
      this.$emit("onTextChanged", x.text);
      this.$emit("onItemSelected", x);
    },
    onChanged(x) {
      this.item = { text: x };
      this.items = suggester.find(x, this.type);
      this.$emit("onTextChanged", x);
    },
    onNav(down) {
      let next = down
        ? $(".vs__item-active.vs__list-item").next(".vs__list-item")
        : $(".vs__item-active.vs__list-item").prev(".vs__list-item");
      if(next.length ==0)
        return
      let list = $(".vs__list");
      let d = next.offset().top - list.offset().top;
      let inview = d <= list.height() && d >= 0;
      if (inview) return;

      if (down) {
        next[0].scrollIntoView({
          block: "end" 
        })
      }
      else{
        next[0].scrollIntoView({
          block: "start" 
        })
      }
    },
  },
  mounted() {},
  watch: {
    value(v) {
      if (this.item.text == v) return;
      this.item.text = v;
    },
  },
};
</script>