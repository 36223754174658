<template>
  <div id="app-container" class="d-flex flex-column dock">
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import rout from "./rout.js";
import jQuery from "jquery";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VuejsDialog from "vuejs-dialog";
import VuejsDialogMixin from "vuejs-dialog/dist/vuejs-dialog-mixin.min.js"; // only needed in custom components
import { bus } from "@/EventBus";
import Toasted from "vue-toasted";
Vue.use(Toasted);

window.jQuery = window.$ = jQuery;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VuejsDialog);
Vue.use(VuejsDialogMixin);
import time from "@/time.js";

export default {
  router: rout,
  created() {
    bus.$on("error", (x) => {
      let msg = x;
      if (msg.msg) msg = msg.msg;
      if (msg.message) msg = msg.message;
      if (msg.errCode) {
        let err = this.$t(msg.errCode);
        if (msg.errCode == "TimeOverlap") {
          for (let i = 0; i < msg.items.length; i++) {
            const it = msg.items[i];
            err = `${err} ${it.title1}(${time.toText(it.start1)}-${time.toText(it.end1)}) >< ${it.title2}(${time.toText(it.start2)}-${time.toText(it.end2)})`;
          }
        }
        msg = err;
      }

      this.$toasted.show(this.$t(msg), {
        theme: "bubble",
        position: "bottom-right",
        duration: 5000,
      });
    });
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/dist/bootstrap-vue";
@import "node_modules/vuejs-dialog/dist/vuejs-dialog.min";
@import "styles/site.scss";
</style>
