<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand v-bind:href="urole | homeLink">Aibiz Online Monitor (v{{version}})</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="urole==0 || urole==2">
          <b-nav-item href="#/my-stats">{{$t('Statistics')}}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-if="urole==1 || urole==2">
          <b-nav-item href="#/videos">{{$t('Videos')}}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-if="urole==1 || urole==2">
          <b-nav-item href="#/channels">{{$t('Channels')}}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-if="urole==1 || urole==2">
          <b-nav-item href="#/import-data">{{$t('Package')}}</b-nav-item>
        </b-navbar-nav>
        <!-- <b-navbar-nav v-if="urole==1 || urole==2">
          <b-nav-item href="#/tools">{{$t('Tools')}}</b-nav-item>
        </b-navbar-nav> -->
        <b-navbar-nav v-if="urole==1 || urole==2">
          <b-nav-item href="#/report">{{$t('Report')}}</b-nav-item>
          <b-nav-item href="#/manage">{{$t('Manage')}}</b-nav-item>
          <b-nav-item href="#/system" v-if="urole==1">{{$t('System')}}</b-nav-item>
          <b-nav-item @click="devClick()" v-if="urole==1">{{$t('Dev')}}</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-item href="#/docs/2">{{$t('???')}}</b-nav-item> -->
          <b-nav-item href="#/docs/policies">{{$t('Policies')}}</b-nav-item>
          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              <span>{{name}}</span>
            </template>
            <b-dropdown-item href="#/profile">{{$t('Profile')}}</b-dropdown-item>
            <b-dropdown-item @click="signout">{{$t('Sign out')}}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import api from "@/api.js";
import { bus } from "@/EventBus";
export default {
  data() {
    return {
      name: null,
      urole: 0,
      version: process.env.VUE_APP_VERSION,
      dev: process.env.VUE_APP_DEV
    };
  },
  methods: {
    signout() {
      api.signout().done(() => {
        this.$router.replace("/login");
      });
    },
    load() {
      this.urole = localStorage.Role;
      this.name = localStorage.User;
    },
    devClick(){
      api.dev();
    }
  },
  mounted() {
    this.load();
    bus.$on("auth", () => {
      this.load();
    });
  },
  filters: {
    homeLink(r) {
      if (r == 2) return "#/qc";
      return "#/monitor";
    },
  },
};
</script>