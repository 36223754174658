<template>
    <input ref="input" class="form-control time input-1" placeholder="00:00:00" v-model="displayText"
        :disabled="disabled" @keyup="onKeyUp($event)" @keypress="acceptTyping($event)" @focusout="updateValue()" />
</template>
<script>
import time from "@/time.js";

String.prototype.insert = function (index, string) {
    if (index > 0) {
        return this.substring(0, index) + string + this.substring(index, this.length);
    }

    return string + this;
};

export default {
    components: {},
    props: {
        value: { type: Number },
        disabled: {},
    },

    data() {
        return {
            displayText: undefined
        };
    },
    methods: {
        acceptTyping: function (evt) {
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            let isNum = charCode >= 48 && charCode <= 57
            if (isNum || evt.key == "-") {
                return true
            }
            evt.preventDefault()
        },
        onKeyUp(evt) {// eslint-disable-line no-unused-vars
            if (this.displayText && this.displayText != "") {
                let sign = this.displayText[0] == '-' ? -1 : 1;
                let txt = this.displayText
                if (sign < 0) {
                    txt = txt.substr(1)
                }
                txt = txt.replace(/:/g, "");
                if (txt.length > 4) {
                    txt = txt.insert(2, ":")
                    txt = txt.insert(1 + 4, ":")
                }
                else if (txt.length > 2) {
                    txt = txt.insert(2, ":")
                }

                if (txt.length > 8)
                    txt = txt.substring(0, 8)

                if (sign < 0) {
                    txt = "-" + txt
                }

                if (this.displayText != txt)
                    this.displayText = txt
            }
        },
        updateValue() {
            let v = this.value
            if (this.displayText == undefined
                || this.displayText == null
                || this.displayText == "") {
                v = null
            }
            else {
                let sign = this.displayText[0] == '-' ? -1 : 1;
                let txt = this.displayText
                if (sign < 0) {
                    txt = txt.substr(1)
                }
                txt = txt.replace(/:/g, "");
                if (txt.length > 4) {
                    txt = txt.insert(2, ":")
                    txt = txt.insert(1 + 4, ":")
                }
                else if (txt.length > 2) {
                    txt = txt.insert(2, ":")
                }

                v = txt == "" ? 0 : time.toSecs(txt) * sign
            }
            if (v != this.value) {
                this.$emit('input', v)
            }
        },
        focus() {
        }
    },
    watch: {
        value: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val, oldVal) {// eslint-disable-line no-unused-vars
                if (!this.value)
                    this.displayText = null
                else {
                    let sign = this.value < 0 ? -1 : 1
                    let v = Math.abs(this.value)
                    let t = time.toText(v)
                    if (sign < 0)
                        t = "-" + t
                    this.displayText = t
                }
            }
        }
    }
};
</script>