<template>
  <div class="docs">
    <iframe :src="id|embeddedUrl"></iframe>
  </div>
</template>
<script>
import api from '@/api'
export default {
  props: ["id"],
  data() {
    return {
      url: this.id,
    };
  },
  filters: {
    embeddedUrl(i) {
      if(api.authenticated()){
        return i;
      }
      if (i == 'policies')
        return "https://drive.google.com/file/d/1fq6rc2OU-5VnccbqNq5BD3bJmE_5wb6h/preview";
      return i;
    },
  },
};
</script>