<template>
  <div class="channels d-flex flex-column h-100">
    <div class="flex-fill scroll-area">
      <div class="container">
        <div class="row">
          <table class="table col-md-10 offset-md-1 shadow-sm content">
            <thead>
              <tr>
                <th>Key</th>
                <th>Import date</th>
                <th>Videos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in items" :key="i.id">
                <td>{{ i.key }}</td>
                <td class="import-date">{{ i.date }}</td>
                <td>
                  <div>
                    <span class="sm-text black"
                      ><a
                        :href="`/#/videos?imp=${i.key}&opt=0`"
                        v-bind:title="$t('total videos')"
                      >
                        {{ i.video_count }}</a
                      ></span
                    >
                    <span class="sm-text good"
                      ><a
                        :href="`/#/videos?imp=${i.key}&opt=1`"
                        v-bind:title="$t('remaining videos')"
                        >{{ i.video_count - i.video_complete_count }}</a
                      ></span
                    >
                    <span class="sm-text blue"
                      ><a
                        :href="`/#/videos?imp=${i.key}&opt=1`"
                        v-bind:title="$t('completed videos')"
                        >{{ i.video_complete_count }}</a
                      ></span
                    >
                    <span class="sm-text last bad"
                      ><a
                        :href="`/#/videos?imp=${i.key}&opt=2`"
                        v-bind:title="$t('skipped videos')"
                        >{{ i.video_skip }}</a
                      ></span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
export default {
  data() {
    return {
      items: [],
    };
  },
  methods: {
    load() {
      api.getImportData().done((x) => {
        this.items = x;
      });
    },
  },
  mounted() {
    this.load();
  },
};
</script>