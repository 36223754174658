<template>
  <div class="d-flex flex-column h-100 stat">
    <div class="p-2 d-flex flex-row toolbar" v-if="urole == 1">
      <button type="button" class="btn btn-primary" @click="newUser">
        {{ $t("New user") }}
      </button>
      <button
        v-if="cUser"
        type="button"
        class="btn btn-warning"
        @click="lockUser"
      >
        {{ $t("Lock user") }}
      </button>
      <button
        v-if="cUser"
        type="button"
        class="btn btn-danger"
        @click="delUser"
      >
        {{ $t("Delete user") }}
      </button>
    </div>
    <div class="mb-auto p-2 flex-fill content">
      <div class="d-flex flex-row flex-fill h-100">
        <div class="d-flex flex-column flex-fill w-80">
          <div class="flex-fill scroll-area">
            <table class="table tableFixHead">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" class="sort-col" @click="sortField('name')">
                    Name
                  </th>
                  <th scope="col" class="sort-col" @click="sortField('email')">
                    Email
                  </th>
                  <th
                    scope="col"
                    class="sort-col"
                    @click="sortField('lock_assignment')"
                  >
                    Auto
                  </th>
                  <th
                    scope="col"
                    class="sort-col"
                    @click="sortField('last_seen')"
                  >
                    Last seen
                  </th>
                  <th scope="col" class="sort-col" @click="sortField('role')">
                    Role
                  </th>
                  <th scope="col">Monitor(Videos | Records)</th>
                  <th scope="col">QC(Records)</th>
                </tr>
              </thead>
              <tbody class="padding-sm content">
                <tr
                  v-for="(it) in vusers"
                  class="selectable"
                  :key="it.id"
                  v-on:click="viewStat(it)"
                  v-bind:class="[
                    `ustatus-${it.active}`,
                    it.id == (cUser && cUser.id) ? 'selected' : '',
                  ]"
                >
                  <td>{{ it.id }}</td>
                  <td>{{ it.name }}</td>
                  <td>{{ it.email }}</td>
                  <td class="lock-assignment">
                    <button @click="toggleAutoAssign(it)">
                      {{ it.lock_assignment | lockAssignDisplay }}
                    </button>
                  </td>
                  <td>{{ it.last_seen }}</td>
                  <td>{{ it.role | roleDisplay }}</td>
                  <td>
                    <span class="stat-item">
                      {{ it.statistics.d0_remain }}/
                      {{ it.statistics.d0_complete }}
                    </span>
                    <span class="stat-item last">
                      {{ it.statistics.d0_records }}/
                      <i class="stat-good">{{
                        it.statistics.d0_records_good
                      }}</i>
                    </span>
                  </td>
                  <td>
                    <span class="stat-item last">{{
                      it.statistics.d1_records
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="summary container">
          <ul class="nav nav-tabs">
            <li class="nav-item" v-on:click="statView = 0">
              <a class="nav-link" v-bind:class="{ active: statView == 0 }"
                >Summary</a
              >
            </li>
            <li class="nav-item" v-on:click="statView = 1">
              <a class="nav-link" v-bind:class="{ active: statView == 1 }"
                >User</a
              >
            </li>
          </ul>
          <div v-if="statView == 0">
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Channels:</td>
                    <td>{{ sysStat.channels }}</td>
                  </tr>
                  <tr>
                    <td>Videos:</td>
                    <td>{{ sysStat.videos }}</td>
                  </tr>
                  <tr>
                    <td>Remaining:</td>
                    <td><span class="good">{{ sysStat.videos_remain-sysStat.videos_disable }}</span><span class="bad del">({{ sysStat.videos_disable }})</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Complete</th>
                    <th>Skip</th>
                    <th>Records</th>
                    <th>Good records</th>
                    <th>QC(records)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="i in sysStat.entries" :key="i.uid">
                    <td>{{ i.title }}</td>
                    <td>{{ i.videos_complete }}</td>
                    <td class="stat-bad">{{ i.videos_skip }}</td>
                    <td>{{ i.records }}</td>
                    <td class="stat-good">
                      {{ i.records_good }} ({{
                        ((i.records_good / i.records) * 100).toFixed(1)
                      }}%)
                    </td>
                    <td>{{ i.records_qc }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="statView == 1">
            <h5>User: {{ cUser.name ? cUser.name : cUser.email }}</h5>
            <UserStats v-bind:uid="cUser.id"></UserStats>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
import Vue from "vue";
import NewUser from "@/components/NewUser";
import VuejsDialog from "vuejs-dialog";
import UserStats from "@/components/UserStats";

Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  okText: "OK",
  cancelText: "Cancel",
  animation: "bounce",
});
Vue.dialog.registerComponent("new-user", NewUser);
export default {
  components: { UserStats },
  name: "Manage",
  props: {
    msg: String,
  },
  data() {
    return {
      urole: 0,
      sortedField: {},
      users: [],
      statView: 0,
      sysStat: {
        videos: 0,
        channels: 0,
        complete: 0,
        videos_remain: 0,
        skips: 0,
        days: [{ title: "Today" }, { title: "Yesterday" }],
      },
      userStat: [],
      cUser: null,
    };
  },
  methods: {
    sortField(f) {
      let field = { name: f, order: 1 };
      if (this.sortedField.name == field.name) {
        if (this.sortedField.order == 0) field.order = 1;
        else if (this.sortedField.order == 1) field.order = -1;
        else if (this.sortedField.order == -1) field = 0;
      }
      this.sortedField = field;
    },
    viewStat(user) {
      this.cUser = user;
      this.statView = 1;
    },
    newUser() {
      this.$dialog
        .confirm("New User", {
          view: "new-user",
          html: true,
          animation: "fade",
          backdropClose: true,
        })
        .then(() => {
          this.load(false);
        });
    },
    load(stat = true) {
      this.urole = localStorage.Role;

      api.getUsers().done((x) => {
        this.users = x;
      });
      if (stat)
        api.sysStat().done((x) => {
          this.sysStat = x;
        });
    },
    autoAssign() {
      api.autoAssign().done(() => {
        this.load();
      });
    },
    toggleAutoAssign(v) {
      if (!v.lock_assignment) {
        this.$dialog
          .confirm(`Are you sure you want to turn off auto assign for: ${v.email}`)
          .then(() => {
            return api.lockAssignment(v.id, true);
          })
          .then(() => {
            v.lock_assignment=!v.lock_assignment;
          });
      }
      else{
        api.lockAssignment(v.id, false)
        .then(()=>{
            v.lock_assignment=!v.lock_assignment;
        })
      }
    },
    delUser() {
      this.$dialog
        .confirm(`Are you sure you want to delete: ${this.cUser.email}`)
        .then(() => {
          return api.delUser(this.cUser.id);
        })
        .then(() => {
          this.statView = 0;
          this.cUser = null;
          this.load(false);
        });
    },
    lockUser() {
      this.$dialog
        .confirm(`Are you sure you want to lock: ${this.cUser.email}`)
        .then(() => {
          return api.lockUser(this.cUser.id);
        })
        .then(() => {
          this.load(false);
        });
    },
  },
  computed: {
    vusers() {
      return this.users.slice().sort((a, b) => {
        let wa = 0;
        let wb = 0;
        if (!a.active) wa = -999;
        if (!b.active) wb = -999;
        if (this.sortedField && this.sortedField.name) {
          let av = a[this.sortedField.name];
          let bv = b[this.sortedField.name];
          let o = this.sortedField.order;
          av = av ? av : "";
          bv = bv ? bv : "";
          if (av > bv) {
            wa += o;
            wb -= o;
          } else if (av < bv) {
            wa -= o;
            wb += o;
          }
        }
        let d = wb - wa;
        if (d < 0) return -1;
        if (d > 0) return 1;
        return 0;
      });
    },
  },
  mounted() {
    this.load();
  },
  filters: {
    roleDisplay(r) {
      if (r == 0) return "Monitor";
      if (r == 1) return "Admin";
      if (r == 2) return "QC";
    },
    lockAssignDisplay(v) {
      if (v) return "No";
      return "Yes";
    },
  },
};
</script>
