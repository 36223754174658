<template>
  <div class="new-user">
    <h5 class="card-title">{{tr('Skip')}}</h5>
    <b-form-group>
      <b-form-select v-model="error_code" class="mb-3">
        <b-form-select-option value="-1" disabled>{{tr('Please select a reason')}}</b-form-select-option>
        <b-form-select-option value="3">{{tr('No music')}}</b-form-select-option>
        <b-form-select-option value="4">{{tr('Video does not play')}}</b-form-select-option>
        <b-form-select-option value="5">{{tr('Private video')}}</b-form-select-option>
        <b-form-select-option value="6">{{tr('Deleted video')}}</b-form-select-option>
        <b-form-select-option value="2">{{tr('Other reason')}}</b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group label-for="txtOther" v-if="error_code==2">
      <b-form-textarea id="txtOther" v-model="error_description" v-bind:placeholder="tr('Write the reason')" :max="1000"></b-form-textarea>
    </b-form-group>
    <div class="clearfix toolbar">
      <button class="btn btn-primary float-right" @click="handleOk">OK</button>
      <button class="btn btn-secondary float-right" @click="handleCancel">Cancel</button>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
import VuejsDialogMixin from "vuejs-dialog/dist/vuejs-dialog-mixin.min.js";
export default {
  mixins: [VuejsDialogMixin],
  data() {
    return {
      error_code: -1,
      error_description: "",
      video: null,
      mode: 'monitor'
    };
  },
  methods: {
    handleOk() {
      api.reportError(this.video, this.error_code, this.error_description, this.mode).done(() => {
        this.proceed();
      });
    },
    handleCancel() {
      this.cancel();
    },
    tr(key){
      return this.p.$t(key)
    }
  },
  mounted() {
    this.video = this.$props.options.video
    this.mode = this.$props.options.mode
  },
  beforeMount(){
    this.p = this.$props.options.p
  }
};
</script>