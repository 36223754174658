const time = {
    pad(num) {
        return ("0" + num).slice(-2);
    },
    hhmmss(secs) {
        var minutes = Math.floor(secs / 60);
        secs = secs % 60;
        var hours = Math.floor(minutes / 60)
        minutes = minutes % 60;
        return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
        // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
    },
    toText(secs) {
        if (!secs)
            return null
        let sign = secs < 0 ? -1 : 1
        let v = Math.abs(secs)
        let t = this.hhmmss(v)
        while (t.startsWith("00:00:")) {
            t = t.substring(3)
        }
        if (sign < 0)
            t = "-" + t
        return t;
    },
    toDurationText(t1, t2) {
        return `${this.toText(t1)} ${this.toText(t2)}`
    },
    validate(text) {
        if (!text)
            return false
        text = text.trim()
        let chk = text.split(/[\s:]+/)
        if (chk.length > 3 || chk.length == 0)
            return false;
        for (let i = 0; i < chk.length; i++) {
            const it = chk[i];
            let v = parseInt(it)
            if (isNaN(v))
                return false
            if (i == 0 && (v > 99 || v < 0))
                return false
            if (i > 0 && (v > 59 || v < 0))
                return false
        }
        return true
    },
    toSecs(text) {
        if (typeof text === 'number')
            return text;
        text = text.trim()
        let chk = text.split(/[\s:]+/).reverse()
        let secs = 0
        for (let i = 0; i < chk.length; i++) {
            const it = chk[i];
            let v = parseInt(it);
            if (isNaN(v))
                throw 'Invalid input'
            if (i == 0) {
                secs += v
            }
            else if (i == 1) {
                secs += v * 60
            }
            else if (i == 2) {
                secs += v * 60 * 60
            }
            else
                throw 'Invalid input'
        }
        return secs
    }
}

export default time;