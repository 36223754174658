<template>
  <div class="row">
    <div class="col-md-6 offset-md-3 shadow-sm content">
      <UserStats></UserStats>
    </div>
  </div>
</template>
<script>
import UserStats from "@/components/UserStats";
export default {
  components: { UserStats },
};
</script>