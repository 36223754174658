<template>
  <div>
    <div v-if="userStat.type=='monitor'">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Complete</th>
            <th>Skip</th>
            <th>Records</th>
            <th>Good</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in userStat.entries" :key="i.uid">
            <td>{{ i.title }}</td>
            <td>{{ i.videos_complete }}</td>
            <td class="stat-bad">{{ i.videos_skip }}</td>
            <td>{{ i.records }}</td>
            <td class="stat-good">{{ i.records_good }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="userStat.type=='qc'">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Records</th>
            <th>New</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in userStat.entries" :key="i.uid">
            <td>{{ i.title }}</td>
            <td>{{ i.records }}</td>
            <td>{{ i.records_new +i.monitor_stat.records }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
export default {
  props: {
    uid: Number,
    self: Boolean,
  },
  data() {
    return {
      userStat: {
        entries: [],
        type: "monitor",
      },
    };
  },
  methods: {
    load() {
      api.userStat(this.uid).done((x) => {
        this.userStat = x;
      });
    },
  },
  watch: {
    uid() {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
};
</script>