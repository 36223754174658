import $ from "jquery";
import _ from "@/deferred"
import { bus } from "@/EventBus";

let url = process.env.VUE_APP_API
let url2 = process.env.VUE_APP_API2
let app_version = process.env.VUE_APP_VERSION

function sendError(x) {
  let msg = x;
  if (x.responseJSON) {
    if (x.responseJSON.errCode) {
      msg = x.responseJSON;
    }
    else {
      msg = x.responseJSON.error;
    }
  }
  else if (x.responseText) {
    msg = x.responseText;
  }
  else {
    msg = `${x.status}|${x.statusText}|${x.state()}`;
  }
  bus.$emit('error', { msg: msg });
}
const api = {
  post(url, data) {
    return $.ajax({
      type: "POST",
      url: url,
      data: data,
      headers: {
        "Authorization": localStorage.Authorization,
        "app-version": app_version
      }
    })
      .fail((x) => {
        sendError(x);
      })
      .done((x, status, xhr) => {
        let auth = xhr.getResponseHeader('Authorization');
        if (auth)
          localStorage.Authorization = auth;
      })
  },
  get(url, data) {
    return $.ajax({
      type: "GET",
      url: url,
      data: data,
      headers: {
        "Authorization": localStorage.Authorization,
        "app-version": app_version
      }
    })
      .fail((x) => {
        sendError(x);
      })
      .done((x, status, xhr) => {
        let auth = xhr.getResponseHeader('Authorization');
        if (auth)
          localStorage.Authorization = auth;
      })
  },
  getVideos(pIndex, mode) {
    return this.get(`${url}/videos?page=${pIndex}&mode=${mode}`);
  },
  getRecords(pIndex, mode) {
    return this.get(`${url}/records?page=${pIndex}&mode=${mode}`);
  },
  getRecord(id, mode) {
    return this.get(`${url}/record/${id}?mode=${mode}`);
  },
  qc_done(rid, mode) {
    return this.post(`${url}/qc/done/${rid}?mode=${mode}`);
  },
  qc_change(record, mode) {
    return this.post(`${url}/qc/edit?mode=${mode}`, record);
  },
  qc_request_edit(id, mode) {
    return this.post(`${url}/qc/edit-request/${id}?mode=${mode}`);
  },
  requestEdit(id) {
    return this.post(`${url}/videos/edit/${id}`);
  },
  requestQc(id) {
    return this.post(`${url}/qc/edit/${id}`);
  },
  getVideo(id) {
    return this.get(`${url}/video/${id}`);
  },
  getQcVideo(id) {
    return this.get(`${url}/qc/video/${id}`);
  },
  qcSaveRecord(record) {
    return this.post(`${url2}/qc/record`, record);
  },
  report(reportType, params) {
    params.report_type = reportType;
    return this.post(`${url2}/report/generate`, params);
  },
  signin(email, password) {
    return this.post(`${url}/users/login`, { email: email, password: password });
  },
  signout() {
    return _.done();
  },
  complete(video) {
    return this.post(`${url2}/monitor/complete-video/${video.id}`);
  },
  saveRecord(record) {
    return this.post(`${url2}/monitor/save-record`, record);
  },
  deleteRecord(record, mode) {
    return this.post(`${url}/record/del`, { id: record.id, video_id: record.video_id, mode: mode });
  },
  reportError(video, error_code, error_description, mode) {
    return this.post(`${url2}/monitor/skip-video`, {
      vid: video.id,
      error_code: error_code,
      error_description: error_description,
      mode: mode
    });
  },
  sysStat() {
    return this.get(`${url2}/stat/0`);
  },
  userStat(uid) {
    if (uid)
      return this.get(`${url2}/stat/${uid}`);
    else
      return this.get(`${url2}/stat`);
  },
  system() {
    return this.get(`${url}/system`);
  },
  suggestionFor(type) {
    console.log(type)
    let d = []

    for (let i = 0; i < 10; i++) {
      if (type == 'title') {
        d.push({ text: `Beo dat may troi ${i}` })
        d.push({ text: `Ha trang ${i}`, secondField: `Trinh Cong son ${i}` });
      }
      else if (type == 'man') {
        d.push({ text: `Trinh Cong Son ${i}` })
      }
    }
    return _.done(d)
  },
  getUsers() {
    return this.get(`${url}/users`);
  },
  newUser(email, password, role, name) {
    return this.post(`${url2}/user/create`, { email: email, password: password, role: role, name: name });
  },
  profile() {
    return this.get(`${url}/users/profile`)
  },
  saveProfile($name, $cpassword, $password) {
    return this.post(`${url2}/user/save`, { name: $name, cpassword: $cpassword, password: $password })
  },
  autoAssign() {
    return this.post(`${url}/admin/auto-assign`)
  },
  lockAssignment(uid, value) {
    return this.post(`${url2}/user/lock-assignment/${uid}/${value}`)
  },
  delUser(uid) {
    return this.post(`${url2}/user/del/${uid}`)
  },
  lockUser(uid) {
    return this.post(`${url2}/user/lock/${uid}`)
  },
  qcLink(data) {
    return this.post(`${url2}/tool/genlink`, { youtube_id: data.youtube_id, time: data.time, aibiz_code: data.aibiz_code })
  },
  getChannels() {
    return this.get(`${url2}/channel`);
  },
  getImportData() {
    return this.get(`${url2}/channel/get-imports`);
  },
  dev() {
    return this.get(`${url2}/dev`);
  },
  videos(args) {
    return this.get(`${url2}/video`, args);
  },
  requestVideos() {
    return this.post(`${url2}/monitor/request-videos`);
  },
  editVideoDuration(vid, duration) {
    return this.post(`${url2}/video/edit-duration`, { video_id: vid, duration: duration });
  },
}
export default api;