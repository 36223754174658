<template>
  <div class="d-flex flex-column h-100 justify-content-center align-items-center login">
    <div class="content shadow">
      <div class="d-flex justify-content-center">
        <img src="@/assets/favicon.png" />
      </div>
      <form>
        <div class="form-group">
          <label for="email">{{ $t('Email address') }}</label>
          <input v-model="email" type="email" class="form-control" id="email" aria-describedby="emailHelp" 
          :placeholder="$t('Enter email')">
          <small v-if="error" class="form-text text-muted">{{$t('Invalid email or password')}}</small>
        </div>
        <div class="form-group">
          <label for="password">{{$t('Password')}}</label>
          <input v-model="password" type="password" class="form-control" id="password" :placeholder="$t('Password')">
          <small v-if="error" class="form-text text-muted">{{$t('Invalid email or password')}}</small>
        </div>
        <div class="d-flex justify-content-center">
          <button type="submit" class="btn btn-primary" @click="signin">{{$t('Sign in')}}</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import api from '@/api.js';
export default {
  data (){
      return {
        email: '',
        password: '',
        error: false
      }
    },
  methods:{
    signin(e){
      e.preventDefault();
      api.signin(this.email, this.password)
      .done(()=>{
        this.$router.replace('/monitor')
      })
      .fail(()=>{
        this.error=true;
      });
    }
  }
}
</script>
<style lang="scss" scoped>
button{
  width: 10rem;
}
</style>