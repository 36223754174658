<template>
  <div class="d-flex flex-row h-100">
    <div class="report-nav">
      <h5>Filters</h5>
      <div>
        <b-form-input
          id="_title"
          v-model="videoTitle"
          placeholder="Video title"
          right
        ></b-form-input>
        <b-form-input
          id="_youtubeId"
          v-model="youtubeId"
          placeholder="Video Youtube ID"
          right
        ></b-form-input>
        <b-form-input
          id="_channelId"
          v-model="channelId"
          placeholder="Channel ID"
          right
        ></b-form-input>
        <b-form-input
          id="_monitorEmail"
          v-model="monitorEmail"
          placeholder="Monitor Email"
          right
        ></b-form-input>
        <b-form-select
          class="form-control"
          id="_videoStatus"
          v-model="videoStatus"
          :options="videoStatuses"
        ></b-form-select>
        <div class="toolbar">
          <button class="btn btn-primary" @click="load">
            {{ $t("Load") }}
          </button>
        </div>
      </div>
    </div>
    <div class="content flex-fill d-flex flex-column">
      <div class="flex-fill scroll-area">
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Id</th>
              <th>Youtube ID</th>
              <th>Title</th>
              <th>Channel</th>
              <th>Publish date</th>
              <th>View count</th>
              <th>Import</th>
              <th>Import date</th>
              <th>Monitor</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(it, idx) in data" :key="idx">
              <td><input type="checkbox" /></td>
              <td><a :href="`/#/monitor/${it.id}`">{{ it.id }}</a> </td>
              <td>{{ it.ext_id }}</td>
              <td>{{ it.title }}</td>
              <td>{{ it.channel_id }}</td>
              <td>{{ moment(it.publish_date).format("YYYY-MM-DD") }}</td>
              <td></td>
              <td>{{ it.import_key }}</td>
              <td>{{ moment(it.created_date).format("YYYY-MM-DD") }}</td>
              <td>{{ it.monitor_full_name }}</td>
              <td>{{ it.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
export default {
  data() {
    return {
      videoStatuses: [
        {
          value: 0,
          text: this.$t("All"),
          disabled: true,
        },
        {
          value: 1,
          text: this.$t("Complete"),
        },
        {
          value: 2,
          text: this.$t("Uncomplete"),
        },
      ],
      videoStatus: 0,
      report: null,
      start: null,
      end: null,
      paging: {
        data: [],
        pageCount: 10,
        pageSize: 100,
        cPage: 0,
        nav(idx) {
          this.cPage = idx;
        },
        setData(x) {
          this.data = x;
          this.cItems = x.slice(0, this.pageSize);
          this.pageCount = x.length / this.pageSize;
        },
        cItems: [{}, {}],
      },
      data: null,
      object: null,
      args: {
        channel: null,
        option: 0,
        import_key: null
      },
    };
  },
  methods: {
    load() {
      api.videos(this.args).done((x) => {
        this.data = x;
      });
    },
  },
  mounted() {
    if (this.$router.currentRoute.query.cn)
      this.args.channel = parseInt(this.$router.currentRoute.query.cn);
    if (this.$router.currentRoute.query.opt)
      this.args.option = parseInt(this.$router.currentRoute.query.opt);
    if (this.$router.currentRoute.query.imp)
      this.args.import_key = this.$router.currentRoute.query.imp;
    this.load();
  },
};
</script>