<template>
  <div class="suggestion-item" v-bind:id="`sItem_${item.index}`">
    <span class="pop-first" v-html="item.text_format"></span>
    <span v-if="item.author" class="pop-second">/ {{item.author}}</span>
  </div>
</template>
 
<script>
export default {
  props: {
    item: { required: true },
  }
}
</script> 